/**
 * Module dependencies.
 */

import { Checkbox } from 'src/components/core/forms/checkbox';
import { FC } from 'react';
import { FormGroup, FormGroupProps } from 'src/components/core/forms/form-group';
import { Radio } from 'src/components/core/forms/radio';
import { useFormContext } from 'react-hook-form';
import { useTranslate } from 'src/context/i18n';
import styled from 'styled-components';

/**
 * Export `OptionsGroupProps` type.
 */

export type OptionsGroupProps = Omit<FormGroupProps, 'size' | 'variant'> & {
  options: {
    id?: string;
    label: string;
    value: string;
  }[];
  type: 'radio' | 'checkbox';
};

/**
 * `optionComponents` config.
 */

const optionComponents: Record<OptionsGroupProps['type'], FC<any>> = {
  checkbox: Checkbox,
  radio: Radio
};

/**
 * `OptionsWrapper` styled component.
 */

const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 8px;

  &[data-type='radio'] {
    gap: 8px;
    padding: 8px var(--labels-gap) 0;
  }
`;

/**
 * Export `OptionsGroup` component.
 */

export const OptionsGroup = ({ name, options, required, type, ...rest }: OptionsGroupProps) => {
  const { register } = useFormContext();
  const { t } = useTranslate();
  const Option = optionComponents[type];

  return (
    <FormGroup required={required} {...rest}>
      <OptionsWrapper data-type={type}>
        {options.map(option => {
          return (
            <Option
              id={`${name}-${option.id ?? option.value}`}
              key={option.value}
              label={option.label}
              value={option.value}
              {...register(name as string, {
                required: required && t('forms.fields.validation.required')
              })}
            />
          );
        })}
      </OptionsWrapper>
    </FormGroup>
  );
};
