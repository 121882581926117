import { useEffect, useState } from 'react';

/**
 * Export `useIsSystem12H` hook.
 *
 * Detects if the browser displays time inputs with AM/PM indicators.
 *
 * This is a hacky solution that works by:
 * 1. Creating a time input with a specific value (23:00) and controlled styles
 * 2. Measuring its rendered width to determine format
 *
 * Browser time inputs can show either 24h ("23:00") or 12h ("11:00 PM") format. The format depends on system/browser
 * settings and can't be detected via API. Time inputs with AM/PM are wider due to the extra text. Testing shows that
 * the input is ~100px with AM/PM vs ~50px without, hence the 75px threshold
 */

export function useIsSystem12H() {
  const [has12Hour, setHas12Hour] = useState(false);

  useEffect(() => {
    const input = document.createElement('input');

    Object.assign(input, { type: 'time', value: '23:00' });
    Object.assign(input.style, {
      border: 'none',
      boxSizing: 'content-box',
      fontFamily: 'Arial',
      fontSize: '16px',
      padding: '0',
      position: 'absolute',
      visibility: 'hidden'
    });

    document.body.appendChild(input);
    setHas12Hour(input.offsetWidth > 75);
    document.body.removeChild(input);
  }, []);

  return has12Hour;
}
