/**
 * Export `formatPercentage` util.
 */

export function formatPercentage(value: number, locale: string) {
  const normalized = value - 1;
  const formatted = normalized.toLocaleString(locale, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'percent'
  });

  return `${normalized > 0 ? '+' : ''}${formatted}`;
}

/**
 * Export `formatMonthNumeric` util.
 */

export function formatMonthNumeric(date: Date | number | string, locale: string) {
  return new Date(date).toLocaleString(locale, {
    month: '2-digit',
    year: 'numeric'
  });
}

/**
 * Export `formatMonthShort` util.
 */

export function formatMonthShort(date: Date | number | string, locale: string) {
  return new Date(date).toLocaleString(locale, {
    month: 'short'
  });
}

/**
 * Export `formatMonthLong` util.
 */

export function formatMonthLong(date: Date | number | string, locale: string) {
  return new Date(date).toLocaleString(locale, {
    month: 'long',
    year: 'numeric'
  });
}

/**
 * Export `formatMonthSlug` util.
 */

export function formatMonthSlug(date: Date | number | string) {
  return new Date(date).toISOString().slice(0, 7);
}

/**
 * Export `formatWeekdayShort` util.
 */

export function formatWeekdayShort(date: Date | number | string, locale: string) {
  return new Date(date)
    .toLocaleString(locale, {
      weekday: 'short'
    })
    .slice(0, 2);
}

/**
 * `siPrefixes` constant.
 */

const siPrefixes = ['', 'K', 'M', 'G', 'T'];

/**
 * Export `formatFileSize` util.
 */

export const formatFileSize = (bytes: number, precision = 1) => {
  const roundingFactor = 10 ** precision;

  for (const [index, prefix] of siPrefixes.entries()) {
    const rounded = Math.round(Math.abs(bytes) * roundingFactor) / roundingFactor;

    if (rounded < 1024 || index === siPrefixes.length - 1) {
      return `${rounded} ${index === 0 ? '' : `${prefix}i`}B`;
    }

    bytes /= 1024;
  }
};
