/**
 * Module dependencies.
 */

import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { getDayDiff } from 'src/core/utils/dates';
import { getDcaData } from './queries-client';
import { useDca } from 'src/context/dca';

/**
 * Export `DcaQueryData` type.
 */

export type DcaQueryData = {
  history: {
    price: number;
    time: number;
  }[];
  lastMedian: number;
};

/**
 * Export `useDcaData` hook.
 */

export function useDcaData(options?: Omit<UseQueryOptions<DcaQueryData>, 'queryFn' | 'queryKey'>) {
  const { dateEnd, dateStart, params, repeatDays, updateDcaState } = useDca();
  const query = useQuery({
    ...options,
    queryFn: async () => {
      const data = await getDcaData(params);
      const newDateEnd = new Date(data.history.at(-1)?.time ?? dateEnd);
      const newDateStart = new Date(data.history[0]?.time ?? dateStart);

      updateDcaState({
        ...(getDayDiff(dateEnd, newDateEnd) > repeatDays * 2 ? { dateEnd: newDateEnd } : {}),
        ...(getDayDiff(dateStart, newDateStart) > repeatDays * 2 ? { dateStart: newDateStart } : {})
      });

      return data;
    },
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['dca-data', params]
  });

  return query;
}
