/**
 * Module dependencies.
 */

import { Container } from 'src/components/core/layout/container';
import { LegalHeroSectionFragment } from 'src/api/entities/sections/legal-hero/types';
import { Media } from 'src/components/media';
import { RawHtml } from 'src/components/core/raw-html';
import { blurFadeInTopAnimation } from 'src/core/constants/motion';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { textStyles } from 'src/styles/typography';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * `Section` styled component.
 */

const Section = styled.section`
  overflow: hidden;
  position: relative;
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  padding-bottom: 40px;
  padding-top: 224px;

  ${media.min.ms`
    padding-bottom: 120px;
    padding-top: 272px;
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageOverlay = styled.div`
  background: var(--hero-black-overlay-gradient);
  inset: 0;
  position: absolute;
`;

/**
 * `Title` styled component.
 */

const Title = styled(motion.h1)`
  ${textStyles.heading1}

  color: var(--color-neutral0);
`;

/**
 * Export `LegalHeroSection` component.
 */

export function LegalHeroSection(props: LegalHeroSectionFragment) {
  const { anchorId, containerSize, media, theme, title, ...rest } = props;

  return (
    <Section data-theme={theme} {...omit(rest, 'id')} {...(anchorId && { id: anchorId })}>
      <Media imageProps={{ fill: true, priority: true }} media={media} />

      <ImageOverlay />

      <StyledContainer size={containerSize}>
        <Title {...blurFadeInTopAnimation(0.1)}>
          <RawHtml>{title}</RawHtml>
        </Title>
      </StyledContainer>
    </Section>
  );
}
