/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, forwardRef } from 'react';
import { Text } from 'src/components/core/text';
import styled from 'styled-components';

/**
 * Export `RadioProps` type.
 */

export type RadioProps = Omit<ComponentPropsWithoutRef<'input'>, 'type'> & {
  label: string;
};

/**
 * `RadioWrapper` styled component.
 */

const RadioWrapper = styled.span`
  input:checked ~ & {
    --radio-border-color: var(--color-primary);
    --radio-border-width: 6px;
  }

  input:disabled ~ & {
    --radio-border-color: var(--color-neutral05);
  }

  input:checked:disabled ~ & {
    --radio-border-color: var(--color-neutral05);
    --radio-border-width: 4px;
  }

  input:hover:not(:disabled):not(:checked) ~ & {
    --radio-background-color: var(--color-neutral05);
    --radio-border-color: var(--color-neutral30);
    --radio-border-width: 2px;

    [data-theme='dark'] & {
      --radio-background-color: var(--color-neutral90);
      --radio-border-color: var(--color-neutral50);
    }
  }

  background-color: var(--radio-background-color);
  border: var(--radio-border-width) solid var(--radio-border-color);
  border-radius: 50%;
  grid-area: radio;
  height: var(--radio-size);
  position: relative;
  transition: var(--transition-default);
  transition-property: background-color, border;
  width: var(--radio-size);
`;

/**
 * `LabelWrapper` styled component.
 */

const LabelWrapper = styled.label<Pick<RadioProps, 'disabled'>>`
  --radio-background-color: transparent;
  --radio-border-color: var(--color-neutral10);
  --radio-border-width: 2px;
  --radio-label-color: var(--color-text);
  --radio-size: 20px;
  --radio-column-gap: 16px;

  [data-theme='dark'] & {
    --radio-border-color: var(--color-neutral70);
  }

  align-items: flex-start;
  cursor: pointer;
  display: grid;
  grid-column-gap: var(--radio-column-gap);
  grid-template-areas: 'radio label';
  grid-template-columns: var(--radio-size) 1fr;
  position: relative;
  width: 100%;
`;

/**
 * `Input` styled component.
 */

const Input = styled.input<Pick<RadioProps, 'disabled'>>`
  cursor: pointer;
  grid-area: radio;
  height: var(--radio-size);
  opacity: 0;
  width: var(--radio-size);
  z-index: 1;
`;

/**
 * `Label` styled component.
 */

const Label = styled(Text).attrs({
  variant: 'paragraph2'
})<Pick<RadioProps, 'disabled'>>`
  grid-area: label;
  transition: color var(--transition-default);
  word-break: break-word;
`;

/**
 * Export `Radio` component.
 */

export const Radio = forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const { disabled, label, ...rest } = props;

  return (
    <LabelWrapper disabled={disabled}>
      <Input disabled={disabled} ref={ref} type={'radio'} {...rest} />

      <Label>{label}</Label>

      <RadioWrapper />
    </LabelWrapper>
  );
});

/**
 * `Radio` display name.
 */

Radio.displayName = 'Radio';
