/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, useRef } from 'react';
import { Container } from 'src/components/core/layout/container';
import { HeadingRowsSectionFragment } from 'src/api/entities/sections/heading-rows/types';
import { Media } from 'src/components/media';
import { Row, getEntryDirection } from './row';
import { SectionWrapper } from 'src/components/core/layout/section';
import { media } from 'src/styles/media';
import { useScrollProgress } from 'src/hooks/use-scroll-progress';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = ComponentPropsWithoutRef<'section'> & HeadingRowsSectionFragment;

/**
 * `StyledSectionWrapper` styled component.
 */

const StyledSectionWrapper = styled(SectionWrapper)`
  --section-padding-bottom: 48px;

  background-color: transparent;
  margin-bottom: -50vh;
  overflow: hidden;
  padding-bottom: calc(50vh + var(--section-padding-bottom));
  position: relative;
  z-index: 1;

  ${media.min.ms`
    --section-padding-bottom: 120px;
  `}
`;

/**
 * `Underlay` styled component.
 */

const Underlay = styled.div`
  background-color: var(--color-background);
  inset: 0 0 50vh;
  pointer-events: none;
  position: absolute;
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  background-color: var(--color-background);
  overflow: visible;
  position: relative;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 232px;
  position: relative;

  ${media.min.ms`
    padding-top: 120px;
  `}

  ${media.min.md`
    gap: 16px;
  `}
`;

/**
 * Export `HeadingRowsSection` component.
 */

export function HeadingRowsSection(props: Props) {
  const { anchorId, containerSize, fontSize, media, rows, theme, ...sectionProps } = props;
  const rowRefs = useRef<Map<string, HTMLSpanElement | null>>(new Map());
  const progresses = useScrollProgress(rowRefs);

  return (
    <StyledSectionWrapper data-theme={theme} {...omit(sectionProps, 'id')} {...(anchorId && { id: anchorId })}>
      <Underlay />

      <StyledContainer size={containerSize}>
        <Media imageProps={{ fill: true }} media={media} mobileBreakpoint={'md'} />

        <Grid data-font-size={fontSize}>
          {rows.map(({ align = 'left', content, name }, index) => (
            <Row
              align={align}
              direction={getEntryDirection(align, index, rows.length - 1)}
              key={name}
              progress={progresses.get(name)}
              ref={node => {
                rowRefs.current.set(name, node);
              }}
            >
              {content}
            </Row>
          ))}
        </Grid>
      </StyledContainer>
    </StyledSectionWrapper>
  );
}
