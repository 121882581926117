/**
 * Module dependencies.
 */

import { Checkbox as BaseCheckbox } from 'src/components/core/forms/checkbox';
import { Button } from 'src/components/core/buttons/button';
import { CSSProperties, ComponentPropsWithoutRef, Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import { Checkbox } from './fields/checkbox';
import { Checkboxgroup } from './fields/checkboxgroup';
import { Copy } from './fields/copy';
import { DatePicker } from './fields/date-picker';
import { File } from './fields/file';
import {
  FormFieldCheckbox,
  FormFieldCheckboxgroup,
  FormFieldCopy,
  FormFieldFile,
  FormFieldInput,
  FormFieldRadiogroup,
  FormFieldSelectCountries,
  FormFieldSelectCountriesStates,
  FormFieldSelectCustom,
  FormFragment,
  FormIntegrationData
} from 'src/api/entities/form/types';

import { FormProvider, useForm } from 'react-hook-form';
import { Input } from './fields/input';
import { PhoneInput } from './fields/phone-input';
import { Radiogroup } from './fields/radiogroup';
import { SelectCountries } from './fields/select-country';
import { SelectCustom } from './fields/select-custom';
import { SelectStates } from './fields/select-state';
import { Spinner } from 'src/components/core/spinner';
import { TimePicker } from './fields/time-picker';
import { media } from 'src/styles/media';
import { useFormMutation } from './use-form-mutation';
import { useTranslate } from 'src/context/i18n';
import arrowRight from 'src/assets/svgs/24/arrow-right.svg';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = ComponentPropsWithoutRef<'form'> & FormFragment;

/**
 * `FormWrapper` styled component.
 */

const FormWrapper = styled.form`
  align-items: start;
  display: flex;
  flex-wrap: wrap;
  gap: 24px 8px;
  justify-content: start;
  scroll-margin-top: calc(var(--padding-top) + 32px);

  > div {
    flex-basis: calc(var(--display-template, 100) * 1% - 16px);
    flex-grow: 1;
    flex-shrink: 0;
  }
`;

/**
 * `TermsCheckbox` styled component.
 */

const TermsCheckbox = styled(BaseCheckbox)`
  ${media.min.md`
    [data-layout='one-column'] & {
      --display-template: 50;
      grid-template-areas: '. . input label' '. helptext helptext helptext';
      grid-template-columns: 1fr max-content 36px max-content;
      padding-top: 12px;
      padding-right: 24px;
    }
  `}
`;

/**
 * `getIntegrationData` function.
 */

function getIntegrationData(data: any): FormIntegrationData {
  if ('kickofflabs' in data) {
    return {
      integration: 'kickofflabs',
      kickofflabs: data.kickofflabs
    };
  }

  if ('directus' in data) {
    return {
      directus: data.directus,
      integration: 'directus'
    };
  }

  return {
    integration: 'url_forward',
    urlForward: data.urlForward
  };
}

/**
 * Export `Form` component.
 */

export const Form = ({ fields, hasSubmissionEvents, id, name, submitLabel, terms, ...rest }: Props) => {
  const { t } = useTranslate();
  const termsId = useMemo(() => `${name}-terms`, [name]);
  const defaultValues = useMemo(
    () =>
      fields.reduce(
        (acc, field) => {
          if (field.type === 'checkbox') {
            acc[field.checkbox.name] = field.defaultValue === 'true';
          }

          if (field.type === 'checkboxgroup') {
            acc[field.checkboxgroup.name] = field.defaultValue ?? null;
          }

          if (field.type === 'file') {
            acc[field.file.name] = [];
          }

          if (field.type === 'input') {
            acc[field.input.name] = field.defaultValue ?? (field.input.type === 'date' ? undefined : '');
          }

          if (field.type === 'radiogroup') {
            acc[field.radiogroup.name] = field.defaultValue ?? null;
          }

          if (field.type === 'select' && field.select.type === 'countries') {
            acc[field.select.name] = null;
          }

          if (field.type === 'select' && field.select.type === 'countries_with_states') {
            acc[field.select.name] = null;
            acc[field.select.stateField.name] = null;
          }

          if (field.type === 'select' && field.select.type === 'custom') {
            acc[field.select.name] = field.defaultValue ?? null;
          }

          return acc;
        },
        { [termsId]: false } as Record<string, any>
      ),
    [fields, termsId]
  );

  const formFieldsRemainder = useMemo(
    () => fields.reduce((total, field) => (total + field.displayTemplate) % 100, 0),
    [fields]
  );

  const formRef = useRef<HTMLFormElement>(null!);
  const form = useForm({ defaultValues, mode: 'onBlur' });
  const { formState, handleSubmit, register, reset, trigger } = form;

  const handleReset = useCallback(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const { isPending, mutate } = useFormMutation({
    handleReset,
    hasSubmissionEvents,
    integrationData: getIntegrationData(rest),
    name
  });

  const onSubmit = useMemo(
    () =>
      handleSubmit(data => {
        mutate(data);
      }),
    [handleSubmit, mutate]
  );

  const errors = formState.errors as Record<string, { message?: string }>;

  useEffect(() => {
    const onHashChange = () => {
      if (window.location.hash.slice(1) === id) {
        formRef.current.querySelector('input')?.focus();
      }
    };

    onHashChange();
    window.addEventListener('hashchange', onHashChange);

    return () => window.removeEventListener('hashchange', onHashChange);
  }, [id]);

  return (
    <FormProvider {...form}>
      <FormWrapper
        aria-disabled={isPending}
        data-tight-labels
        id={id}
        noValidate
        onSubmit={onSubmit}
        ref={formRef}
        {...omit(rest, ['urlForward', 'kickofflabs', 'directus', 'integration'])}
      >
        {fields.map(field => {
          switch (field.type) {
            case 'checkbox':
              return <Checkbox {...(field as FormFieldCheckbox)} key={field.id} />;

            case 'checkboxgroup':
              return <Checkboxgroup {...(field as FormFieldCheckboxgroup)} key={field.id} />;

            case 'copy':
              return <Copy {...(field as FormFieldCopy)} key={field.id} />;

            case 'file':
              return <File {...(field as FormFieldFile)} key={field.id} />;

            case 'input':
              if (field.input.type === 'date') {
                return <DatePicker {...field} key={field.id} />;
              }

              if (field.input.type === 'time') {
                return <TimePicker {...field} key={field.id} />;
              }

              if (field.input.type === 'phone') {
                return <PhoneInput {...field} key={field.id} />;
              }

              return <Input {...(field as FormFieldInput)} key={field.id} />;

            case 'radiogroup':
              return <Radiogroup {...(field as FormFieldRadiogroup)} key={field.id} />;

            case 'select':
              return (
                <Fragment key={field.id}>
                  {field.select.type === 'custom' && <SelectCustom {...(field as FormFieldSelectCustom)} />}

                  {['countries', 'countries_with_states'].includes(field.select.type) && (
                    <SelectCountries {...(field as FormFieldSelectCountries | FormFieldSelectCountriesStates)} />
                  )}

                  {field.select.type === 'countries_with_states' && (
                    <SelectStates {...(field as FormFieldSelectCountriesStates)} />
                  )}
                </Fragment>
              );

            default:
              return null;
          }
        })}

        {!!formFieldsRemainder && <div style={{ '--display-template': formFieldsRemainder } as CSSProperties} />}

        <TermsCheckbox
          error={errors[termsId]?.message}
          id={termsId}
          label={terms}
          labelHasHtml
          {...register(termsId, {
            onChange: () => trigger(termsId),
            required: t('forms.fields.validation.terms')
          })}
        />

        <Button
          disabled={isPending}
          icon={arrowRight}
          style={{ marginLeft: 'auto' }}
          type={'submit'}
          variant={'primary'}
        >
          <Spinner active={isPending} size={16} />

          {submitLabel}
        </Button>
      </FormWrapper>
    </FormProvider>
  );
};
