/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, forwardRef, useState } from 'react';
import { FormGroup, FormGroupProps } from 'src/components/core/forms/form-group';
import { Icon, dateTimeFormControlStyles } from 'src/components/core/forms/date-time/common';
import { Picker } from './picker';
import clockIcon from 'src/assets/svgs/16/clock.svg';
import closeIcon from 'src/assets/svgs/16/close.svg';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * Export `TimePickerProps` interface.
 */

export type TimePickerProps = Omit<ComponentPropsWithoutRef<'input'>, 'onChange' | 'size' | 'value' | 'placeholder'> &
  FormGroupProps & {
    onChange: (time: string) => void;
    value: string;
  };

/**
 * FormControl styled component.
 */

const FormControl = styled.input<Pick<TimePickerProps, 'error'>>`
  ${dateTimeFormControlStyles}

  &::-webkit-calendar-picker-indicator {
    display: none;
  }
`;

/**
 * Export `TimePicker` component.
 */

export const TimePicker = forwardRef<HTMLInputElement, TimePickerProps>((props, ref) => {
  const {
    className,
    disabled,
    error,
    helpText,
    id,
    label,
    onChange,
    size = 'default',
    value,
    variant = 'filled',
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <FormGroup
      aria-expanded={isOpen}
      className={className}
      data-size={size}
      data-variant={variant}
      disabled={disabled}
      error={error}
      helpText={helpText}
      id={id}
      label={label}
      onClick={event => {
        event.stopPropagation();
        setIsOpen(isOpen => !isOpen);
      }}
      role={'button'}
      style={{
        position: 'relative'
      }}
      tabIndex={0}
      value={value}
    >
      <div style={{ gridArea: 'input', position: 'relative' }}>
        <Icon icon={clockIcon} size={'20px'} style={{ left: 14 }} />

        <FormControl
          {...omit(rest, ['as'])}
          error={error}
          id={id}
          onChange={({ target }) => onChange(target.value)}
          ref={ref}
          type={'time'}
          value={value}
        />

        {value && (
          <Icon
            icon={closeIcon}
            onClick={event => {
              event.stopPropagation();
              onChange('');
            }}
            size={'16px'}
            style={{ right: 12 }}
          />
        )}

        <Picker isOpen={isOpen} onChange={onChange} onRequestClose={() => setIsOpen(false)} value={value} />
      </div>
    </FormGroup>
  );
});

/**
 * `TimePicker` display name.
 */

TimePicker.displayName = 'TimePicker';
