/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef } from 'react';
import { Media } from 'src/components/media';
import { TeamMemberFragment } from 'src/api/entities/sections/team-members/types';
import { Text } from 'src/components/core/text';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import styled from 'styled-components';

/**
 * `TeamMemberCardProps` type.
 */

type TeamMemberCardProps = TeamMemberFragment & ComponentPropsWithoutRef<'div'>;

/**
 * `StyledMedia` component.
 */

const StyledMedia = styled(Media)`
  border-radius: var(--border-radius);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
`;

/**
 * `CardWrapper` component.
 */

const CardWrapper = styled.div`
  border: none;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  height: 408px;
  justify-content: flex-end;
  outline: none;
  position: relative;
  transition: var(--transition-default);
  transition-property: box-shadow, opacity, transform, filter;

  :focus-visible,
  :hover {
    box-shadow: var(--box-shadow-lightL);
    cursor: pointer;
    transform: scale(1.01);
    z-index: 1;

    [data-theme='dark'] & {
      box-shadow: var(--box-shadow-darkL);
    }
  }

  ${media.max.ms`
    aspect-ratio: 5 / 7;
    height: 100%;
    width: 100%;
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  color: var(--color-text);
  display: flex;
  flex-direction: column;
  padding: 24px;
  z-index: 1;
`;

/**
 * `ImageOverlay` styled component.
 */

const ImageOverlay = styled.div`
  --overlay-gradient: linear-gradient(
    180deg,
    color-mix(in srgb, var(--color-neutral0), transparent 100%) 20%,
    color-mix(in srgb, var(--color-neutral0), transparent 90%) 40%,
    color-mix(in srgb, var(--color-neutral0), transparent 60%) 60%,
    color-mix(in srgb, var(--color-neutral0), transparent 40%) 80%,
    var(--color-neutral0) 100%
  );

  [data-theme='dark'] & {
    --overlay-gradient: linear-gradient(
      180deg,
      color-mix(in srgb, var(--color-neutral95), transparent 100%) 20%,
      color-mix(in srgb, var(--color-neutral95), transparent 90%) 40%,
      color-mix(in srgb, var(--color-neutral95), transparent 60%) 60%,
      color-mix(in srgb, var(--color-neutral95), transparent 40%) 80%,
      var(--color-neutral95) 100%
    );
  }

  background: var(--overlay-gradient);
  border-radius: var(--border-radius);
  inset: 0;
  position: absolute;
`;

/**
 * `Name` styled component.
 */

const Name = styled(Text).attrs({ fontWeight: 700, variant: 'subtitle2' })`
  ${headingStyles}
`;

/**
 * `JobTitle` styled component.
 */

const JobTitle = styled(Text).attrs({ variant: 'paragraph2' })`
  ${headingStyles}
`;

/**
 * Export `TeamMemberCard` component.
 */

export const TeamMemberCard = (props: TeamMemberCardProps) => {
  const { jobTitle, media, name, theme, ...rest } = props;

  return (
    <CardWrapper data-theme={theme} {...rest}>
      <StyledMedia media={media} />

      <ContentWrapper>
        <Name>{name}</Name>

        <JobTitle>{jobTitle}</JobTitle>
      </ContentWrapper>

      <ImageOverlay />
    </CardWrapper>
  );
};
