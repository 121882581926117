/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Container } from 'src/components/core/layout/container';
import { FieldValues, useForm } from 'react-hook-form';
import { HTMLAttributes, useCallback } from 'react';
import { IconButton } from 'src/components/core/buttons/icon-button';
import { Image } from 'src/components/core/image';
import { SectionWrapper } from 'src/components/core/layout/section';
import { SubscriptionSectionFragment } from 'src/api/entities/sections/subscription/types';
import { Text } from 'src/components/core/text';
import { axiosNextInstance } from 'src/api/lib/next';
import { media } from 'src/styles/media';
import { regexes } from 'src/core/constants/regexes';
import { textStyles } from 'src/styles/typography';
import { toast } from 'src/context/toast';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useDeviceSource } from 'src/hooks/use-device-source';
import { useTranslate } from 'src/context/i18n';
import arrowRightIcon from 'src/assets/svgs/24/arrow-right.svg';
import bellIcon from 'src/assets/svgs/20/bell.svg';
import styled from 'styled-components';

/**
 * Constants.
 */

const isRecaptchaEnabled = process.env.NEXT_PUBLIC_RECAPTCHA_ENABLED === 'true';

/**
 * Export `SubscriptionContentProps` type.
 */

export type SubscriptionContentProps = SubscriptionSectionFragment &
  HTMLAttributes<HTMLDivElement> & {
    action?: string;
    className?: string;
    layout?: 'default' | 'card';
    rules: {
      required: string;
      validEmail: string;
    };
  };

/**
 * `Section` styled component.
 */

const Section = styled(SectionWrapper)`
  display: flex;
  justify-content: center;

  ${media.max.ms`
    & > div > div {
      aspect-ratio: 4 / 5;
      max-height: 500px;
      max-width: 400px;
    }
  `}
`;

/**
 * `CenteredContainer` styled component.
 */

const CenteredContainer = styled(Container)`
  display: flex;
  justify-content: center;

  > div {
    margin: 0 var(--gutter-cards);
  }
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: var(--color-neutral95);
  border-radius: 16px;
  overflow: hidden;
  position: relative;

  &[data-layout='card'] {
    align-items: center;
    display: grid;
  }
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-row-gap: 32px;
  grid-template-areas: 'lead ' 'form';
  grid-template-columns: 100%;
  grid-template-rows: 1fr min-content;
  height: 100%;
  padding: var(--subscription-grid-padding, 24px);
  position: relative;

  ${media.min.ms`
    [data-layout='default'] & {
      grid-template-areas: '. lead .' '. form .';
      grid-template-columns: 6fr 25fr 21fr;
      grid-template-rows: auto;
      padding: 86px 0 106px;
    }

    [data-layout='card'] & {
      grid-template-rows: min-content min-content;
      height: min-content;
    }
  `}
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Text).attrs({ block: true, variant: 'heading2' })`
  color: var(--color-white);
  font-weight: 400;
  grid-area: lead;
`;

/**
 * `FormGroup` styled component.
 */

const FormGroup = styled.div`
  align-items: center;
  background-color: var(--color-neutral0);
  border: 1px solid var(--color-neutral10);
  border-radius: 56px;
  box-shadow: var(--box-shadow-darkS);
  display: flex;
  gap: 8px;
  height: max-content;
  justify-content: space-between;
  padding: 2px 2px 2px 0;
  transition: var(--transition-default);
  transition-property: background-color, border-color, color;
  width: 100%;

  [data-layout='card'] & {
    justify-content: center;
  }

  :hover {
    border-color: var(--color-neutral30);
  }

  :focus,
  :focus-within {
    border-color: var(--color-neutral70);
  }

  &[data-error='true'] {
    border-color: var(--color-critical60);
  }
`;

/**
 * FormControl styled component.
 */

const FormControl = styled.input`
  ${textStyles.label2}

  appearance: none;
  background: none;
  border: none;
  border-radius: 56px 0 0 56px;
  color: var(--color-neutral95);
  outline: none !important;
  padding: 13px 8px 13px 24px;
  width: 100%;

  ::placeholder {
    color: var(--color-neutral40);
    opacity: 1;
  }
`;

/**
 * `ErrorMessage` styled component.
 */

export const ErrorMessage = styled(Text).attrs({ variant: 'small' })`
  color: var(--color-critical50);
  left: 24px;
  position: relative;

  ${media.min.ms`
    [data-layout='default'] & {
      bottom: -24px;
      position: absolute;
    }
  `}
`;

/**
 * Export `SubscriptionContentComponent` component.
 */

export const SubscriptionContentComponent = (props: SubscriptionContentProps) => {
  const { action, anchorId, asset, assetMobile, className, form, id, layout = 'default', lead, rules, ...rest } = props;
  const { t } = useTranslate();
  const isMobile = useBreakpoint(0, 'ms');
  const file = useDeviceSource([asset, assetMobile]);
  const name = `email-${id}`;

  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset
  } = useForm<FieldValues>();

  const error = errors[name]?.message;
  const SubmitButton = !isMobile && layout !== 'card' ? Button : IconButton;

  const onSubmit = useCallback(
    async (data: FieldValues) => {
      const email = Object?.values(data)?.[0];
      let recaptchaAction = null;
      let recaptchaToken = null;

      if (isRecaptchaEnabled) {
        recaptchaAction = action || 'subscription';
        recaptchaToken = await window.grecaptcha.enterprise?.execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY, {
          action: recaptchaAction
        });
      }

      return axiosNextInstance
        .post('subscription', { email, id, recaptchaAction, recaptchaToken })
        .then(() => {
          reset();
          toast.success(form?.successMessage, { autoClose: 3000 });
        })
        .catch(error => {
          toast.error(t(error?.data?.message || 'subscription.server.error.submit'));
        });
    },
    [action, form?.successMessage, id, reset, t]
  );

  return (
    <Wrapper className={className} data-layout={layout} {...rest} {...(anchorId && { id: anchorId })}>
      {file?.url && <Image alt={file?.alt || lead} fill src={file.url} />}

      <Grid>
        <Lead>{lead}</Lead>

        <form data-theme={'dark'} onSubmit={handleSubmit(onSubmit)} style={{ gridArea: 'form' }}>
          <div style={{ position: 'relative' }}>
            <FormGroup data-error={!!error}>
              <FormControl
                disabled={isSubmitting}
                placeholder={form.inputPlaceholder}
                type={'email'}
                {...register(name, {
                  pattern: {
                    message: rules.validEmail,
                    value: regexes.email
                  },
                  required: rules.required
                })}
              />

              <SubmitButton
                aria-label={form.ctaLabel}
                as={'button'}
                disabled={isSubmitting}
                icon={isMobile && layout !== 'card' ? bellIcon : arrowRightIcon}
                size={'large'}
                type={'submit'}
                variant={'primary'}
              >
                {form.ctaLabel}
              </SubmitButton>
            </FormGroup>

            {!!error && <ErrorMessage>{error as string}</ErrorMessage>}
          </div>
        </form>
      </Grid>
    </Wrapper>
  );
};

/**
 * Export `SubscriptionSection` component.
 */

export const SubscriptionSection = (props: SubscriptionSectionFragment) => {
  const { t } = useTranslate();

  return (
    <Section>
      <CenteredContainer>
        <SubscriptionContentComponent
          {...props}
          rules={{
            required: t('subscription.form.email.rule.required'),
            validEmail: t('subscription.form.email.rule.validEmail')
          }}
        />
      </CenteredContainer>
    </Section>
  );
};
