/**
 * Module dependencies.
 */

import { Container } from 'src/components/core/layout/container';
import { LegalIndexSectionFragment } from 'src/api/entities/sections/legal-index/types';
import { Link } from 'src/components/core/links';
import { RawHtml } from 'src/components/core/raw-html';
import { SectionWrapper } from 'src/components/core/layout/section';
import { Svg } from 'src/components/core/svg';
import { fadeInLeftAnimation, fadeInTopAnimation } from 'src/core/constants/motion';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { textStyles } from 'src/styles/typography';
import arrowIcon from 'src/assets/svgs/24/arrow-right.svg';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * `StyledSectionWrapper` styled component.
 */

const StyledSectionWrapper = styled(SectionWrapper)`
  --topics-max-width: 352px;

  padding: 32px 0 48px;

  ${media.min.ms`
    padding: 56px 0 120px;
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  padding-top: 24px;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  ${media.min.ms`
    display: grid;
    grid-column-gap: 8px;
    grid-template-areas: 'title ctaList' 'description ctaList';
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: min-content 1fr;
    padding-top: 72px;

    &:not(:last-child) {
      margin-bottom: 72px;
    }
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(motion.h2)`
  ${textStyles.heading2}
  ${headingStyles}
  
  margin-bottom: 16px;
  max-width: var(--topics-max-width);
  padding-top: 16px;

  ${media.min.ms`
    grid-area: title;
    padding-right: 40px;
  `}
`;

/**
 * `Description` styled component.
 */

const Description = styled(motion.p)`
  ${textStyles.paragraph2}

  color: var(--color-neutral70);
  font-weight: 400;
  margin-bottom: 16px;
  max-width: var(--topics-max-width);

  ${media.min.ms`
    grid-area: description;
    padding-right: 32px;
  `}
`;

/**
 * `CtasList` styled component.
 */

const CtasList = styled.ul`
  padding-top: 24px;

  ${media.min.ms`
    grid-area: ctaList;
    padding-right: 8px;
  `}
`;

/**
 * `CtasListItem` styled component.
 */

const CtasListItem = styled(motion.li)`
  ${media.max.ms`
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  `}
`;

/**
 * `Arrow` styled component.
 */

const Arrow = styled(Svg).attrs({ icon: arrowIcon, size: '20px' })`
  color: var(--color-primary);
  top: 2px;
  transition: transform var(--transition-fast);
`;

/**
 * `CtasListItemLink` styled component.
 */

const CtasListItemLink = styled(Link)`
  ${textStyles.paragraph1}

  color: var(--color-text);
  display: grid;
  font-weight: 400;
  gap: 8px;
  grid-template-columns: 20px 1fr;

  &[href]:hover {
    opacity: 0.6;

    ${Arrow} {
      transform: translateX(2px);
    }
  }

  ${media.min.ms`
    margin-bottom: 0;
    padding: 12px 0 12px 32px;
  `}
`;

/**
 * Export `LegalIndexSection` type.
 */

export function LegalIndexSection({ anchorId, items, theme, ...rest }: LegalIndexSectionFragment) {
  if (!items || items.length === 0) {
    return null;
  }

  return (
    <StyledSectionWrapper data-theme={theme} {...omit(rest, 'id')} {...(anchorId && { id: anchorId })}>
      <Container size={'narrow'}>
        {items.map(({ anchorId, description, id, links, title }) => (
          <Grid key={id + title} {...(anchorId && { id: anchorId })}>
            <Title {...fadeInTopAnimation()}>{title}</Title>

            {description && (
              <Description {...fadeInTopAnimation(0.05)}>
                <RawHtml>{description}</RawHtml>
              </Description>
            )}

            {!!links && links.length > 0 && (
              <CtasList>
                {links.map(({ href, label, target }, index) => (
                  <CtasListItem {...fadeInLeftAnimation(0.1)} key={label + index}>
                    <CtasListItemLink aria-label={label} href={href} {...(!!target && { target })}>
                      <Arrow id={'svg-arrow'} />

                      {label}
                    </CtasListItemLink>
                  </CtasListItem>
                ))}
              </CtasList>
            )}
          </Grid>
        ))}
      </Container>
    </StyledSectionWrapper>
  );
}
