/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { CSSProperties, ComponentPropsWithoutRef, useMemo } from 'react';
import { Container } from 'src/components/core/layout/container';
import { HeroSectionFragment } from 'src/api/entities/sections/hero/types';
import { Media } from 'src/components/media';
import { RawHtml } from 'src/components/core/raw-html';
import { Text } from 'src/components/core/text';
import { blurFadeInTopAnimation, fadeInAnimation } from 'src/core/constants/motion';
import { bulletStyles, headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { textStyles } from 'src/styles/typography';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * Constants.
 */

const delay = 0.1;

/**
 * `Props` type.
 */

type Props = Omit<ComponentPropsWithoutRef<'section'>, 'title'> & HeroSectionFragment;

/**
 * `Section` styled component.
 */

const Section = styled.section`
  background-color: var(--color-background);
  color: var(--color-text);
  overflow: hidden;
  position: relative;
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Container)`
  height: 100vh;
  height: 100svh;
  max-height: 1440px;
  position: relative;
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageOverlay = styled.div`
  --hero-gradient-base-color: var(--color-white);

  background: linear-gradient(
    180deg,
    color-mix(in srgb, var(--hero-gradient-base-color), transparent 100%) 70.26%,
    color-mix(in srgb, var(--hero-gradient-base-color), transparent 30%) 86%,
    var(--hero-gradient-base-color) 100%
  );
  inset: 0;
  position: absolute;

  [data-theme='dark'] & {
    --hero-gradient-base-color: var(--color-neutral105);
  }
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  gap: 16px;
  inset: calc(
      var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 3
    )
    0 40px;
  padding-left: inherit;
  padding-right: inherit;
  position: absolute;

  > div:first-child {
    display: grid;
    gap: 16px;
  }

  ${media.max.ms`
    display: flex;
    flex-direction: column;
    justify-content: var(--layout-mobile, space-between);
  `}

  ${media.min.ms`
    display: grid;

    &[data-layout='top-center'] {
      bottom: auto;
      top: 24vh;
    }

    &[data-layout='bottom-center'] {
      bottom: 10vh;
      top: auto;
    }

    &[data-layout='top-center'],
    &[data-layout='bottom-center'] {
      justify-items: center;
      text-align: center;

      > div:first-child {
        justify-items: center;
      }
    }

    &[data-layout='default'] {
      bottom: 25.5vh;
      top: auto;
    }
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ as: 'h1', variant: 'heading2' })`
  font-weight: 700;
  max-width: 848px;

  ${headingStyles}

  [data-layout='default'] & {
    max-width: 656px;
  }
`;

/**
 * `Pretitle` styled component.
 */

const Pretitle = styled(Text).attrs({ as: 'h3', variant: 'subtitle2' })`
  font-weight: 400;
  margin-bottom: 8px;
  max-width: 848px;

  ${headingStyles}

  [data-layout='default'] & {
    max-width: 656px;
  }
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ as: 'div', variant: 'paragraph1' })`
  font-weight: 500;
  max-width: 584px;

  ${headingStyles}
  ${bulletStyles}

  ul {
    padding-bottom: 24px;
  }

  .p2 {
    ${textStyles.paragraph2}
  }

  [data-layout='default'] & {
    max-width: 568px;
  }
`;

/**
 * `PreCta` styled component.
 */

const PreCta = styled(Text).attrs({ variant: 'subtitle2' })`
  font-weight: 400;

  ${headingStyles}
`;

/**
 * `CtasWrapper` styled component.
 */

const CtasWrapper = styled.div`
  column-gap: 24px;
  display: flex;
  margin-top: 32px;
  row-gap: 16px;

  ${media.max.ms`
    flex-direction: column;
  `}

  ${media.min.ms`
    align-items: center;
  `}
`;

/**
 * `HeroButton` styled component.
 */

const HeroButton = styled(Button)`
  &[data-variant='ghost'] {
    background-color: transparent;
    padding-left: 0 !important;
    transition-property: opacity;

    &:hover {
      opacity: 0.75;
    }
  }
`;

/**
 * Export `HeroSection` component.
 */

export function HeroSection(props: Props) {
  const {
    anchorId,
    containerSize,
    ctas,
    description,
    layout,
    layoutMobile,
    media,
    preCta,
    pretitle,
    theme,
    title,
    ...rest
  } = props;

  const { delays, has, totalDelay } = useMemo(() => {
    const order = ['pretitle', 'title', 'description', 'preCta'] as (keyof typeof has)[];
    const has = {
      description: !isEmpty(description),
      preCta: !isEmpty(preCta),
      pretitle: !isEmpty(pretitle),
      title: !isEmpty(title)
    };

    const delays = order.reduce(
      (acc, key) => {
        const totalDelay = Object.values(acc).filter(Boolean).length * delay;

        if (has[key]) {
          acc[key] = totalDelay + delay;
        }

        return acc;
      },
      {} as Record<keyof typeof has, number>
    );

    return { delays, has, totalDelay: Object.values(delays).filter(Boolean).length * delay };
  }, [description, preCta, pretitle, title]);

  return (
    <Section data-theme={theme} {...omit(rest, 'id')} {...(anchorId && { id: anchorId })}>
      <Media imageProps={{ fill: true, priority: true }} media={media} />

      <ImageOverlay />

      <Wrapper size={containerSize}>
        <Grid data-layout={layout} style={{ '--layout-mobile': layoutMobile } as CSSProperties}>
          <div>
            {has.pretitle && (
              <motion.div {...blurFadeInTopAnimation(delays?.pretitle)}>
                <RawHtml element={Pretitle}>{pretitle}</RawHtml>
              </motion.div>
            )}

            {has.title && (
              <motion.div {...blurFadeInTopAnimation(delays?.title)}>
                <RawHtml element={Title}>{title}</RawHtml>
              </motion.div>
            )}

            {has.description && (
              <motion.div {...blurFadeInTopAnimation(delays?.description)}>
                <RawHtml element={Description}>{description}</RawHtml>
              </motion.div>
            )}
          </div>

          {ctas?.length > 0 && (
            <CtasWrapper>
              {preCta && (
                <motion.div {...blurFadeInTopAnimation(delays?.preCta)}>
                  <RawHtml element={PreCta}>{preCta}</RawHtml>
                </motion.div>
              )}

              {ctas.map(
                (cta, index) =>
                  'href' in cta &&
                  'label' in cta && (
                    <motion.span key={index + cta.label} {...fadeInAnimation((totalDelay ?? 0) + delay * (index + 1))}>
                      <HeroButton {...omit(cta, 'label')} aria-label={cta.label} hasLinkIcon size={'large'}>
                        {cta.label}
                      </HeroButton>
                    </motion.span>
                  )
              )}
            </CtasWrapper>
          )}
        </Grid>
      </Wrapper>
    </Section>
  );
}
