/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { Dropdown } from 'src/components/core/dropdown';
import { DropdownOptions } from 'src/components/core/dropdown/options';
import { Image } from 'src/components/core/image';
import { LoadMore } from 'src/components/core/load-more';
import { Section } from 'src/components/core/layout/section';
import { StakingSectionFragment } from 'src/api/entities/sections/staking/types';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { fadedCards } from 'src/components/sections/cards';
import { media } from 'src/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useMemo, useState } from 'react';
import { useStakingAssets } from 'src/api/entities/staking-assets/hooks';
import { useTranslate } from 'src/context/i18n';
import chevronIcon from 'src/assets/svgs/24/chevron-down.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = StakingSectionFragment;

/**
 * `getAssetLink` function.
 */

const getAssetLink = (code: string) => process.env.NEXT_PUBLIC_STAKING_ASSET_LINK.replace('{{code}}', code);

/**
 * `sortOptions` constant.
 */

const sortOptions: Array<{ label: string; value: `${'' | '-'}${'apy' | 'name'}` }> = [
  {
    label: 'labels.sort.rewardsAscending',
    value: 'apy'
  },
  {
    label: 'labels.sort.rewardsDescending',
    value: '-apy'
  },
  {
    label: 'labels.sort.nameAscending',
    value: 'name'
  },
  {
    label: 'labels.sort.nameDescending',
    value: '-name'
  }
];

/**
 * `Content` styled component.
 */

const Content = styled.div`
  margin: 0 var(--gutter-cards);
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  gap: 8px;

  ${media.min.sm`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.min.ms`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.min.md`
    grid-template-columns: repeat(4, 1fr);
  `}

  &[data-animated='true'] {
    ${fadedCards}
  }
`;

/**
 * `AssetCard` styled component.
 */

const AssetCard = styled.div`
  background-color: var(--color-neutral0);
  border-radius: 16px;
  column-gap: 12px;
  display: grid;
  grid-template-areas:
    'image name name'
    'image shortName shortName'
    'apyLabel apyLabel apyValue';
  grid-template-columns: 48px 1fr max-content;
  grid-template-rows: max-content 1fr max-content;
  height: 144px;
  padding: 16px;

  [data-theme='dark'] & {
    background-color: var(--color-neutral95);
  }

  ${media.min.sm`
    height: 168px;
  `}
`;

/**
 * `AssetName` styled component.
 */

const AssetName = styled(Text).attrs({ fontWeight: 700, variant: 'paragraph1' })`
  display: -webkit-box; /* stylelint-disable-line value-no-vendor-prefix */
  grid-area: name;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * `AssetShortName` styled component.
 */

const AssetShortName = styled(Text).attrs({ variant: 'paragraph2' })`
  color: var(--color-neutral50);
  grid-area: shortName;
`;

/**
 * `ApyLabel` styled component.
 */

const ApyLabel = styled(Text).attrs({ variant: 'paragraph2' })`
  align-self: end;
  grid-area: apyLabel;
  padding-bottom: 4px;
`;

/**
 * `AssetApy` styled component.
 */

const AssetApy = styled(Text).attrs({ fontWeight: 700, variant: 'heading4' })`
  color: var(--color-primary);
  grid-area: apyValue;
`;

/**
 * `SortDropdownWrapper` styled component.
 */

const SortDropdownWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 16px;
  justify-content: end;
  margin-bottom: 24px;
  position: relative;

  ${media.min.md`
    margin-bottom: 32px;
  `}

  & > span[role="button"] > span {
    transition: transform var(--transition-default);
  }

  &[aria-expanded='true'] > span[role='button'] > span {
    transform: scaleY(-1);
  }
`;

/**
 * `usePageSize` hook.
 */

function usePageSize() {
  const isSm = useBreakpoint('sm');
  const isMs = useBreakpoint('ms');
  const isMd = useBreakpoint('md');

  return useMemo(() => {
    if (isMd) {
      return 24;
    }

    if (isMs) {
      return 18;
    }

    if (isSm) {
      return 12;
    }

    return 6;
  }, [isMd, isMs, isSm]);
}

/**
 * Export `StakingSection` component.
 */

export const StakingSection = ({ hasAssetLinks, ...rest }: Props) => {
  const { t } = useTranslate();
  const translatedSortOptions = useMemo(() => sortOptions.map(({ label, value }) => ({ label: t(label), value })), [t]);
  const [isOpen, setIsOpen] = useState(false);
  const [sort, setSort] = useState(translatedSortOptions[1]);
  const pageSize = usePageSize();
  const [page, setPage] = useState(0);
  const { data } = useStakingAssets();
  const sortedStakingAssets = useMemo(
    () =>
      data
        ?.sort((first, second) => {
          if (sort.value === 'name') {
            return first.name.localeCompare(second.name);
          }

          if (sort.value === '-name') {
            return second.name.localeCompare(first.name);
          }

          if (sort.value === 'apy') {
            return first.apy - second.apy;
          }

          return second.apy - first.apy;
        })
        ?.slice(0, (page + 1) * pageSize),
    [data, page, pageSize, sort.value]
  );

  return (
    <Section {...rest}>
      <Content>
        <SortDropdownWrapper aria-expanded={isOpen}>
          <span>{`${t('actions.sortBy')}:`}</span>

          <Button
            onClick={event => {
              event.stopPropagation();
              setIsOpen(isOpen => !isOpen);
            }}
            variant={'neutral'}
          >
            {sort.label}

            <Svg icon={chevronIcon} size={'20px'} />
          </Button>

          <Dropdown isOpen={isOpen} onRequestClose={() => setIsOpen(false)} position={'right'} top={4}>
            <DropdownOptions
              onChange={sort => {
                setPage(0);
                setSort(sort);
                setIsOpen(false);
              }}
              options={translatedSortOptions}
              value={sort.value}
            />
          </Dropdown>
        </SortDropdownWrapper>

        <LoadMore
          hasMore={data && data.length > (page + 1) * pageSize}
          label={t('actions.showMoreAssets')}
          onLoadMore={() => setPage(page => page + 1)}
        >
          <Grid data-animated={hasAssetLinks}>
            {sortedStakingAssets?.map(asset => (
              <AssetCard
                key={asset.code}
                {...(hasAssetLinks && {
                  as: 'a',
                  'data-grid-card': true,
                  href: getAssetLink(asset.code),
                  target: '_blank'
                })}
              >
                <Image
                  alt={`${asset.name} icon`}
                  height={48}
                  src={asset.image}
                  style={{ gridArea: 'image' }}
                  width={48}
                />
                <AssetName>{asset.name}</AssetName>

                <AssetShortName>{asset.shortName}</AssetShortName>

                <ApyLabel>{t('labels.estimatedRewards')}</ApyLabel>

                <AssetApy>{`${asset.apy}%`}</AssetApy>
              </AssetCard>
            ))}
          </Grid>
        </LoadMore>
      </Content>
    </Section>
  );
};
