/**
 * Module dependencies.
 */

import { Card } from 'src/components/core/card';
import { Props as CardProps } from 'src/components/core/card/shared';
import { Section, SectionProps } from 'src/components/core/layout/section';
import { SectionFootnote } from 'src/components/sections/shared/footnote';
import { media } from 'src/styles/media';
import { useCardGridResize } from 'src/hooks/use-card-grid-resize';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = SectionProps & {
  cards: CardProps[];
  footnote?: string;
};

/**
 * `fadedCards` styles.
 */

export const fadedCards = css`
  [data-grid-card] {
    transition: var(--transition-default);
    transition-property: box-shadow, opacity, transform, filter;
  }

  &:has([data-grid-card]:hover) {
    [data-grid-card] {
      &:not(:hover) {
        transform: scale(0.98);
      }

      &:hover {
        box-shadow: var(--box-shadow-lightL);
        transform: scale(1.01);
        z-index: 1;

        [data-theme='dark'] & {
          box-shadow: var(--box-shadow-darkL);
        }
      }
    }
  }
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  align-items: start;
  display: grid;
  grid-gap: var(--grid-row-gap);
  margin: 0 var(--gutter-cards);

  ${media.min.xs`
    margin: 0 auto;
    max-width: 456px;
  `}

  ${media.min.ms`
    grid-template-columns: repeat(6, 1fr);
    justify-content: space-between;
    margin: 0 var(--gutter-cards);
    max-width: unset;
  `}

  ${fadedCards}
`;

/**
 * Export `CardsSection` component.
 */

export const CardsSection = ({ cards, containerSize, footnote, ...rest }: Props) => {
  const safeContainerSize = containerSize === 'fluid' ? 'default' : containerSize;
  const shrinkFactor = useCardGridResize(safeContainerSize);

  return (
    <Section containerSize={safeContainerSize} {...rest}>
      <Grid>
        {cards.map(card => (
          <Card data-grid-card key={card.id} shrinkFactor={shrinkFactor} {...card} />
        ))}
      </Grid>

      {footnote && <SectionFootnote>{footnote}</SectionFootnote>}
    </Section>
  );
};
