/**
 * Module dependencies.
 */

import { IconButton } from 'src/components/core/buttons/icon-button';
import { ModalPortal } from 'src/components/core/modal/portal';
import { RichText } from 'src/components/rich-text';
import { Text } from 'src/components/core/text';
import { Theme } from 'src/types/theme';
import { fadeIn, fadeInRight, fadeOut, fadeOutRight } from 'src/styles/keyframes';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { transparentize } from 'src/styles/utils/colors';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useEffect } from 'react';
import arrowRightSvg from 'src/assets/svgs/24/arrow-right.svg';
import closeSvg from 'src/assets/svgs/24/close.svg';
import styled from 'styled-components';

/**
 * Constants.
 */

const durationMs = 350;
const delayMs = 175;

/**
 * Export `TeamMemberDetailsDrawerProps` type.
 */

export type TeamMemberDetailsDrawerProps = {
  description?: string;
  isOpen: boolean;
  jobTitle?: string;
  name?: string;
  onRequestClose: () => void;
  theme?: Theme;
};

/**
 * `CloseButtonContainer` styled component.
 */

const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 120px 0 0 72px;
  width: 100%;

  ${media.max.ms`
    padding: 48px 0 0 24px;
  `};
`;

/**
 * `Underlay` styled component.
 */

const Underlay = styled.div`
  --team-member-drawer-animation-duration: ${durationMs}ms;
  --team-member-drawer-animation-fill-mode: both;
  --team-member-drawer-animation-timing-function: ease-out;
  --team-member-drawer-content-background-color: var(--color-neutral0);
  --team-member-drawer-underlay-animation-delay: ${delayMs}ms;
  --team-member-drawer-underlay-animation-name: ${fadeOut};
  --team-member-drawer-body-animation-delay: 0;
  --team-member-drawer-body-animation-name: ${fadeOutRight};
  --team-member-drawer-close-button-size: 2.5rem;
  --team-member-drawer-close-button-background-color: var(--color-neutral80);
  --team-member-drawer-close-button-color: var(--color-white);
  --team-member-drawer-close-button-active-color: var(--color-neutral60);
  --background-color: ${transparentize('neutral0', 0.28)};
  --button-color: var(--color-neutral05);

  align-items: center;
  animation-delay: var(--team-member-drawer-underlay-animation-delay);
  animation-duration: var(--team-member-drawer-animation-duration);
  animation-fill-mode: var(--team-member-drawer-animation-fill-mode);
  animation-name: var(--team-member-drawer-underlay-animation-name);
  animation-timing-function: var(--team-member-drawer-animation-timing-function);
  backdrop-filter: blur(8px);
  background-color: var(--background-color);
  display: flex;
  inset: 0;
  justify-content: center;
  opacity: 0;
  overflow-y: auto;
  position: fixed;
  scroll-snap-type: y mandatory;
  z-index: var(--z-index-modal-underlay);

  &[data-active='true'] {
    --team-member-drawer-body-animation-delay: ${delayMs}ms;
    --team-member-drawer-body-animation-name: ${fadeInRight};
    --team-member-drawer-underlay-animation-delay: 0;
    --team-member-drawer-underlay-animation-name: ${fadeIn};
  }

  &[data-theme='dark'] {
    --background-color: ${transparentize('neutral95', 0.28)};
    --button-color: var(--color-neutral90);
    --team-member-drawer-content-background-color: var(--color-neutral95);
  }
`;

/**
 * `ModalContent` styled component.
 */

const ModalContent = styled.div`
  animation-delay: var(--team-member-drawer-body-animation-delay);
  animation-duration: var(--team-member-drawer-animation-duration);
  animation-fill-mode: var(--team-member-drawer-animation-fill-mode);
  animation-name: var(--team-member-drawer-body-animation-name);
  animation-timing-function: var(--team-member-drawer-animation-timing-function);
  background-color: var(--team-member-drawer-content-background-color);
  color: var(--color-text);
  height: 100%;
  margin: 0 0 0 auto;
  max-width: 984px;
  overflow: auto;
  width: 100%;

  ${media.max.ms`
    padding: 48px 0 0;
  `};
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 48px 72px 120px;

  ${media.max.md`
    gap: 24px;
    padding: 48px 24px;
  `};
`;

/**
 * `MainInfo` styled component.
 */

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

/**
 * `Description` styled component.
 */

const Description = styled(RichText).attrs({ variant: 'paragraph1' })`
  ${headingStyles}
`;

/**
 * Export `TeamMemberDetailsDrawer` component.
 */

export const TeamMemberDetailsDrawer = (props: TeamMemberDetailsDrawerProps) => {
  const { description, isOpen, jobTitle, name, onRequestClose, theme } = props;
  const isDesktop = useBreakpoint('md');

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (isOpen && event.key === 'Escape') {
        event.preventDefault();
        onRequestClose();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, onRequestClose]);

  return (
    <ModalPortal isOpen={isOpen} preventBodyScroll={!!isOpen} timeout={durationMs + delayMs}>
      <Underlay
        data-active={isOpen}
        data-theme={theme}
        onClick={event => {
          event.stopPropagation();
          onRequestClose();
        }}
        tabIndex={-1}
      >
        <ModalContent onClick={event => event.stopPropagation()}>
          <CloseButtonContainer>
            <IconButton
              aria-label={'close modal'}
              icon={isDesktop ? arrowRightSvg : closeSvg}
              onClick={onRequestClose}
              size={'large'}
              variant={'neutral'}
            />
          </CloseButtonContainer>

          <Content>
            <MainInfo>
              <Text color={'primary'} fontWeight={700} variant={'heading2'}>
                {name}
              </Text>

              <Text variant={'subtitle1'}>{jobTitle}</Text>
            </MainInfo>

            <Description>{description}</Description>
          </Content>
        </ModalContent>
      </Underlay>
    </ModalPortal>
  );
};
