/**
 * Module dependencies.
 */

import { useEffect, useState } from 'react';

/**
 * Export `useDelayedValue` hook.
 */

export function useDelayedValue<T>(value: T, delay: number = 250): T {
  const [delayedValue, setDelayedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDelayedValue(value), delay);

    return () => clearTimeout(timer);
  }, [value, delay]);

  return delayedValue;
}
