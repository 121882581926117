/**
 * Module dependencies.
 */

import { Card } from 'src/components/core/card';
import { NavigationSectionFragment } from 'src/api/entities/sections/navigation/types';
import { Section } from 'src/components/core/layout/section';
import { media } from 'src/styles/media';
import styled from 'styled-components';

/**
 * `CardGrid` styled component.
 */

const CardGrid = styled.div`
  display: grid;
  grid-gap: var(--grid-row-gap);
  grid-template-columns: 1fr;

  ${media.min.ms`
    grid-template-columns: repeat(4, 1fr);
  `}

  ${media.min.md`
    grid-template-columns: repeat(6, 1fr);

    &[data-size='narrow'] {
      grid-template-columns: repeat(4, 1fr);
    }
  `}
`;

/**
 * Export `NavigationSection` component.
 */

export function NavigationSection(props: NavigationSectionFragment) {
  const { containerSize, items, ...rest } = props;

  if (!items.length) {
    return null;
  }

  return (
    <Section containerSize={containerSize} {...rest}>
      <CardGrid data-size={containerSize}>
        {items.map((card, index) => (
          <Card key={index} {...card} />
        ))}
      </CardGrid>
    </Section>
  );
}
