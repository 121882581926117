/**
 * Module dependencies.
 */

import { CardWrapper } from 'src/components/sections/process-cards/shared';
import { Media } from 'src/components/media';
import { RawHtml } from 'src/components/core/raw-html';
import { StoryCardsSectionFragment } from 'src/api/entities/sections/story-cards/types';
import { Text } from 'src/components/core/text';
import { headingStyles } from 'src/components/core/layout/headings';
import { textStyles } from 'src/styles/typography';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * Export `Props` type.
 */

export type Props = StoryCardsSectionFragment;

/**
 * `CardText` styled component.
 */

const CardText = styled(Text).attrs({ as: 'div', fontWeight: 400, variant: 'subtitle1' })`
  ${headingStyles}

  position: relative;
  z-index: 1;

  h5 {
    ${textStyles.paragraph1}

    font-weight: 400;
    padding-top: 8px;
  }
`;

/**
 * Export `Card` component.
 */

export const Card = ({ item, ...rest }: { item: Props['items'][number] }) => (
  <CardWrapper {...rest} mobileAspectRatio={1}>
    <Media imageProps={{ fill: true }} media={item.media} />

    {!isEmpty(item.content) && <RawHtml element={CardText}>{item.content}</RawHtml>}
  </CardWrapper>
);
