/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, useMemo } from 'react';
import { Container } from 'src/components/core/layout/container';
import { Form } from 'src/components/forms';
import { FormSectionFragment } from 'src/api/entities/sections/form/types';
import { Media } from 'src/components/media';
import { RawHtml } from 'src/components/core/raw-html';
import { Text } from 'src/components/core/text';
import { blurFadeInTopAnimation } from 'src/core/constants/motion';
import { headingStyles } from 'src/components/core/layout/headings';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * Constants.
 */

const delay = 0.1;

/**
 * `Props` type.
 */

type Props = Omit<ComponentPropsWithoutRef<'section'>, 'title'> & FormSectionFragment;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.section`
  --padding-top: calc(
    (var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height) + var(--gutter-navbar-y) * 3) +
      32px
  );
  --color-text: var(--color-black);

  display: grid;

  &[data-layout='one-column'] {
    grid-row-gap: 56px;
  }

  [data-theme='dark'] & {
    --color-text: var(--color-white);
  }

  ${media.max.md`
    align-items: end;
    position: relative;
    gap: 64px;
    padding-bottom: 48px;

    &[data-section-type='hero'] {
      min-height: 100vh;
      min-height: 100svh;
      grid-template-rows: calc(200vh / 3) max-content;
    }
  `}

  ${media.min.md`
    --columns-ratio: 4 / 9;
    --columns-gap: 32px;
    width: 100vw;

    &:not([data-layout='one-column']) {
      grid-template-columns: 1fr 1fr;
    }
  `}

  ${media.min.lg`
    --columns-ratio: 4 / 9;
    --columns-gap: 64px;
  `}

  ${media.min.xl`
    --columns-ratio: 2 / 5;

    &:not([data-size='narrow']) {
      --columns-gap: 168px;
    }
  `}
`;

/**
 * `StickyWrapper` styled component.
 */

const StickyWrapper = styled.div`
  padding-top: var(--padding-top);

  ${media.min.md`
    [data-layout]:not([data-layout='one-column']) & {
      position: sticky;
      height: 100vh;
      top: 0;
      width: 100vw;
    }
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageOverlay = styled.div`
  --hero-gradient-base-color: var(--color-white);

  background: linear-gradient(
    180deg,
    color-mix(in srgb, var(--hero-gradient-base-color), transparent 100%) 70.26%,
    color-mix(in srgb, var(--hero-gradient-base-color), transparent 30%) 86%,
    var(--hero-gradient-base-color) 100%
  );
  position: absolute;

  [data-theme='dark'] & {
    --hero-gradient-base-color: var(--color-neutral105);
  }
`;

/**
 * `Headings` styled component.
 */

const Headings = styled.div`
  color: var(--color-text);
  display: grid;
  gap: 16px;

  ${media.min.md`
    max-width: calc(100% * (1 - var(--columns-ratio)) - var(--columns-gap));

    [data-form-position='left'] & {
      left: 50%;
      position: relative;
    }

    [data-section-type='section'][data-layout='one-column'] & {
      max-width: 100%;

      > * {
       text-align: center;
      }
    }
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Text).attrs({ as: 'h1', variant: 'heading2' })`
  font-weight: 700;

  ${headingStyles}
`;

/**
 * `CenteredTitle` styled component.
 */

const CenteredTitle = styled(Text).attrs({ as: 'h1', variant: 'heading1' })`
  font-weight: 700;

  ${headingStyles}
`;

/**
 * `Pretitle` styled component.
 */

const Pretitle = styled(Text).attrs({ as: 'h3', variant: 'subtitle1' })`
  font-weight: 400;
  margin-bottom: 8px;

  ${headingStyles}
`;

/**
 * `CenteredDescription` styled component.
 */

const CenteredDescription = styled(Text).attrs({ as: 'h2', variant: 'subtitle2' })`
  font-weight: 400;
  margin: 0 auto;
  max-width: 776px;
  text-align: center;

  ${headingStyles}

  ${media.min.md`
    margin-bottom: 8px;
  `}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ as: 'h2', variant: 'paragraph1' })`
  font-weight: 400;
  margin: 0 auto;
  max-width: 776px;

  ${headingStyles}

  ${media.min.md`
    margin-bottom: 8px;
  `}
`;

/**
 * `FormContainer` styled component.
 */

const FormContainer = styled(Container)<any>`
  position: relative;

  &[data-size='extra-narrow'] {
    --container-width: 640px;
    padding-left: var(--gutter);
    padding-right: var(--gutter);
  }

  ${media.min.md`
    --total-container-width: min(100vw, var(--container-width));
    --form-width: calc(var(--total-container-width) * var(--columns-ratio));
    padding-bottom: 72px;

    &[data-layout='two-column'] {
      --headings-width: calc(var(--total-container-width) * (1 - var(--columns-ratio)));

      [data-form-position='left'] & {
        /* stylelint-disable-next-line length-zero-no-unit */
        --headings-width: 0px;
      }

      left: calc(-50vw - min(100vw, var(--total-container-width)) / 2 + var(--headings-width));
      padding-bottom: 120px;
      padding-left: var(--gutter);
      padding-right: var(--gutter);
      padding-top: var(--padding-top);
      width: var(--form-width);
    }
  `}
`;

/**
 * `StyledForm` styled component.
 */

const StyledForm = styled(Form)`
  border-radius: 16px;
  color: var(--color-text);
  padding: 24px 24px 32px;

  &[data-background='true'] {
    background: var(--color-neutral0);

    &[data-theme='dark'] {
      background: var(--color-neutral95);
    }
  }

  [data-layout='one-column'] & {
    position: relative;
  }

  ${media.max.md`
    margin: 0 var(--gutter-cards);
    padding: 24px 16px 32px;
  `}

  [data-layout='two-column'] &,
  [data-size='default'] & {
    ${media.min.md`
      [data-form-position='left'] & {
        margin-left: var(--gutter-cards);
      }

      [data-form-position='right'] & {
        margin-right: var(--gutter-cards);
      }
    `}
  }
`;

/**
 * Export `FormSection` component.
 */

export const FormSection = (props: Props) => {
  const {
    anchorId,
    containerSize,
    description,
    form,
    formContainerSize,
    formPosition,
    formTheme,
    hasFormBackground,
    id,
    layout,
    media,
    pretitle,
    sectionType,
    theme,
    title,
    ...rest
  } = props;

  const isCenteredSection = layout === 'one-column' && sectionType === 'section';
  const { delays, has } = useMemo(() => {
    const order = ['pretitle', 'title', 'description'] as (keyof typeof has)[];
    const has = {
      description: !isEmpty(description),
      pretitle: !isEmpty(pretitle),
      title: !isEmpty(title)
    };

    const delays = order.reduce(
      (acc, key) => {
        const totalDelay = Object.values(acc).filter(Boolean).length * delay;

        if (has[key]) {
          acc[key] = totalDelay + delay;
        }

        return acc;
      },
      {} as Record<keyof typeof has, number>
    );

    return { delays, has };
  }, [description, pretitle, title]);

  return (
    <Wrapper
      {...rest}
      data-form-position={formPosition}
      data-layout={layout}
      data-section-type={sectionType}
      data-size={containerSize}
      id={`${id}-wrapper`}
    >
      <StickyWrapper data-theme={theme}>
        {sectionType === 'hero' && (
          <>
            <Media imageProps={{ fill: true, priority: true }} media={media} style={{ height: '100vh' }} />
            <ImageOverlay />
          </>
        )}

        <Container size={containerSize}>
          <Headings>
            {has.pretitle && (
              <motion.div {...blurFadeInTopAnimation(delays?.pretitle)}>
                <RawHtml element={Pretitle}>{pretitle}</RawHtml>
              </motion.div>
            )}

            {has.title && (
              <motion.div {...blurFadeInTopAnimation(delays?.title)}>
                <RawHtml element={isCenteredSection ? CenteredTitle : Title}>{title}</RawHtml>
              </motion.div>
            )}

            {has.description && (
              <motion.div {...blurFadeInTopAnimation(delays?.description)}>
                <RawHtml element={isCenteredSection ? CenteredDescription : Description}>{description}</RawHtml>
              </motion.div>
            )}
          </Headings>
        </Container>
      </StickyWrapper>

      <FormContainer
        data-form-position={formPosition}
        data-layout={layout}
        id={anchorId}
        size={layout === 'one-column' ? formContainerSize : containerSize}
      >
        <StyledForm {...form} data-background={hasFormBackground} data-theme={formTheme} id={id} />
      </FormContainer>
    </Wrapper>
  );
};
