/**
 * Module dependencies.
 */

import { Input as BaseInput } from 'src/components/core/forms/input';
import { CSSProperties } from 'react';
import { FormFieldInput } from 'src/api/entities/form/types';
import { regexes } from 'src/core/constants/regexes';
import { useFormContext } from 'react-hook-form';
import { useIsVisible } from './use-is-visible';
import { useTranslate } from 'src/context/i18n';

/**
 * Export `Input` field component.
 */

export const Input = (field: FormFieldInput) => {
  const { displayTemplate, id, input } = field;
  const isVisible = useIsVisible(field);
  const {
    formState: { errors },
    register
  } = useFormContext();

  const { t } = useTranslate();

  if (!isVisible) {
    return null;
  }

  return (
    <div style={{ '--display-template': displayTemplate } as CSSProperties}>
      <BaseInput
        error={errors[input.name]?.message as string}
        helpText={input.description}
        id={id}
        label={input.label}
        placeholder={input.placeholder}
        required={input.isRequired}
        {...(input.type === 'textarea'
          ? {
              isTextArea: true
            }
          : {
              type: input.type
            })}
        {...register(input.name, {
          required: input.isRequired && t('forms.fields.validation.required'),
          ...(input.type === 'email' && {
            pattern: { message: t('forms.fields.validation.email'), value: regexes.email }
          })
        })}
      />
    </div>
  );
};
