/**
 * Module dependencies.
 */

import { FormGroup } from 'src/components/core/forms/form-group';
import { Picker } from 'src/components/core/forms/date-time/date-picker/picker';
import { Row } from './shared';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { formControlSizes, formControlStyles, formControlVariants } from 'src/components/core/forms/styles';
import { useFormat } from 'src/hooks/format/use-format';
import { useState } from 'react';
import calendarIcon from 'src/assets/svgs/20/calendar.svg';
import chevronDown from 'src/assets/svgs/24/chevron-down.svg';
import styled from 'styled-components';

/**
 * Export `Props` type.
 */

export type Props = {
  id: string;
  label: string;
  max?: Date;
  min?: Date;
  onChange: (date: Date) => void;
  suffix?: string;
  value: Date;
};

/**
 * `FormControl` styled component.
 */

const FormControl = styled.div`
  ${formControlStyles}
  ${formControlVariants.ghost}
  ${formControlSizes.small}

  align-items: center;
  display: flex;
  font-weight: 400;
  gap: 2px;
  justify-content: end;
  padding-right: calc(var(--input-padding-x) / 2);

  :hover {
    background-color: var(--color-neutral90);
  }
`;

/**
 * `Chevron` styled component.
 */

const Chevron = styled(Svg).attrs({ icon: chevronDown, size: '24px' })`
  color: var(--input-color);
  transition: var(--transition-default);
  transition-property: color, transform;

  [aria-expanded='true'] & {
    transform: rotate(180deg);
  }
`;

/**
 * Export `MonthPicker` component.
 */

export const MonthPicker = ({ id, label, max, min, onChange, suffix, value }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMonthLong } = useFormat();

  return (
    <FormGroup
      aria-expanded={isOpen}
      id={id}
      onClick={event => {
        event.stopPropagation();
        setIsOpen(isOpen => !isOpen);
      }}
      role={'button'}
      tabIndex={0}
      value={value}
    >
      <Row>
        <Svg color={'var(--color-neutral50)'} icon={calendarIcon} size={'20px'} />

        <Text as={'label'} fontWeight={700} htmlFor={id} variant={'paragraph2'}>
          {label}
        </Text>

        <FormControl>
          {[formatMonthLong(value), suffix].join(' ')}

          <Chevron />

          <Picker
            isMonthOnly
            isOpen={isOpen}
            max={max}
            min={min}
            onChange={date => date && onChange(date)}
            onRequestClose={() => setIsOpen(false)}
            value={value}
          />
        </FormControl>
      </Row>
    </FormGroup>
  );
};
