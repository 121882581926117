/**
 * Module dependencies.
 */

import { SectionHeading, SectionProps, SectionWrapper } from 'src/components/core/layout/section';
import omit from 'lodash/omit';

/**
 * `Props` type.
 */

type Props = Omit<SectionProps, 'containerSize' | 'footerCtas'>;

/**
 * Export `HeadingSection` component.
 */

export function HeadingSection(props: Props) {
  const { anchorId, description, pretitle, theme, title, ...rest } = props;

  return (
    <SectionWrapper data-theme={theme ?? 'light'} {...omit(rest, 'id')} {...(anchorId && { id: anchorId })}>
      <SectionHeading description={description} pretitle={pretitle} style={{ paddingTop: 0 }} title={title} />
    </SectionWrapper>
  );
}
