/**
 * Module dependencies.
 */

import { ContainerProps } from 'src/components/core/layout/container';
import { useEffect, useState } from 'react';

/**
 * Export `useCardGridResize` hook.
 */

export function useCardGridResize(containerSize: Exclude<ContainerProps['size'], 'fluid'>) {
  const [shrinkFactor, setShrinkFactor] = useState(1);

  useEffect(() => {
    const parentMaxWidthCssVarName =
      containerSize === 'narrow' ? '--container-narrow-max-width' : '--container-max-width';

    const parentMaxWidth = Number.parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(parentMaxWidthCssVarName),
      10
    );

    const gutter = Number.parseInt(getComputedStyle(document.documentElement).getPropertyValue('--gutter'), 10);

    const onResize = () => {
      const factor = Math.min(1, window.innerWidth / (parentMaxWidth + gutter));
      setShrinkFactor(factor);
    };

    onResize();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [containerSize]);

  return shrinkFactor;
}
