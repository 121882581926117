/**
 * Module dependencies.
 */

import { CSSProperties, useCallback, useMemo, useState } from 'react';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { FileDrop, FileInputProps } from 'src/components/core/forms/file-drop';
import { FileRejection } from 'react-dropzone';
import { FormFieldFile } from 'src/api/entities/form/types';
import { formatFileSize } from 'src/core/utils/format';
import { useIsVisible } from './use-is-visible';
import { useTranslate } from 'src/context/i18n';
import uniqBy from 'lodash/uniqBy';

/**
 * Export `File` field component.
 */

export const File = (field: FormFieldFile) => {
  const isVisible = useIsVisible(field);
  const { displayTemplate, file: props, id } = field;
  const {
    formState: { errors },
    trigger
  } = useFormContext();

  const { t } = useTranslate();

  const [rejections, setRejections] = useState<FileRejection[]>([]);

  const rejectedErrorMessage = useMemo(() => {
    let tooLarge = false;
    let tooSmall = false;

    for (const rejection of rejections) {
      for (const error of rejection.errors) {
        if (error.code === 'file-too-large') {
          tooLarge = true;
        }

        if (error.code === 'file-too-small') {
          tooSmall = true;
        }
      }
    }

    if (tooLarge !== tooSmall) {
      return t(
        `form.fields.validation.file.${tooLarge ? 'max' : 'min'}Size.${rejections.length > 1 ? 'plural' : 'singular'}`,
        {
          size: formatFileSize(props.maxSize as number)
        }
      );
    }

    if (tooLarge && tooSmall) {
      return t('form.fields.validation.file.sizeInterval', {
        maxSize: formatFileSize(props.maxSize as number),
        minSize: formatFileSize(props.minSize as number)
      });
    }
  }, [props.maxSize, props.minSize, rejections, t]);

  const handleDrop: (props: Pick<ControllerRenderProps, 'onChange' | 'value'>) => FileInputProps['onDrop'] =
    useCallback(
      ({ onChange, value }) =>
        (files, rejected) => {
          setRejections(rejected);
          onChange(uniqBy([...(value ?? []), ...(files ?? [])], 'name'));
        },
      []
    );

  if (!isVisible) {
    return null;
  }

  return (
    <div style={{ '--display-template': displayTemplate } as CSSProperties}>
      <Controller
        name={props.name}
        render={({ field: { onChange, ref, value = [] } }) => (
          <FileDrop
            accept={props.accept}
            error={rejectedErrorMessage ?? (errors[props.name]?.message as string)}
            helpText={props.description}
            id={id}
            label={props.label}
            maxFiles={props.maxFiles}
            maxSize={props.maxSize}
            minSize={props.minSize}
            name={props.name}
            onBlur={() => setRejections([])}
            placeholder={props.placeholder}
            ref={ref}
            required={props.isRequired}
            value={value}
            {...(Array.isArray(value) && {
              onDrop: handleDrop({ onChange, value }),
              onRemove: indexToRemove => onChange(value?.filter((_file, index) => index !== indexToRemove))
            })}
          />
        )}
        rules={{
          onChange: () => trigger(props.name),
          required: props.isRequired && t('forms.fields.validation.required'),
          ...(props.maxFiles && {
            validate: value =>
              value?.length <= (props.maxFiles as number) ||
              t('form.fields.validation.file.maxFiles', { max: props.maxFiles })
          })
        }}
      />
    </div>
  );
};
