/**
 * Module dependencies.
 */

import { ContinueSectionFragment } from 'src/api/entities/sections/continue/types';
import { LESSON_PROGRESS_KEY, LessonProgress } from 'src/types/local-storage';
import { LearnCenterSectionFragment } from 'src/api/entities/sections/learn-center/types';
import { LessonCard } from 'src/components/core/card/lesson-card';
import { RouterLink } from 'src/components/core/links/router-link';
import { Section } from 'src/components/core/layout/section';
import { fadedCards } from 'src/components/sections/cards';
import { media } from 'src/styles/media';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useCallback, useMemo } from 'react';
import { useLocalStorage } from 'src/hooks/use-local-storage';
import { useRouter } from 'next/router';
import styled, { CSSProperties } from 'styled-components';

/**
 * `ContinueLessonProps` type.
 */

type ContinueLessonProps = Pick<LearnCenterSectionFragment, 'courses'> & ContinueSectionFragment;

/**
 * `LessonsWrapper` styled component.
 */

const LessonsWrapper = styled.div`
  display: grid;
  gap: 8px;
  margin: 0 var(--gutter-cards) 40px;

  ${media.min.ms`
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: unset;
    margin-bottom: 52px;
  `}

  ${fadedCards};
`;

/**
 * `LessonCardWrapper` styled component.
 */

const LessonCardWrapper = styled.div`
  margin: 0 auto;
  min-height: 472px;
  width: min(456px, 100%);

  ${media.min.ms`
    margin: 0;
    min-height: unset;
    width: unset;

    &[data-lessons-number='2'] {
      min-height: 520px;
    }

    &[data-lessons-number='3'] {
      min-height: 472px;
    }
  `}

  ${media.min.md`
    &[data-lessons-number='2'] {
      min-height: 620px;
    }

    &[data-lessons-number='3'] {
      min-height: 520px;
    }
  `}
`;

/**
 * Export `ContinueLessonSection` component.
 */

export const ContinueLessonSection = ({ courses = [], ...rest }: ContinueLessonProps) => {
  const router = useRouter();
  const isDesktop = useBreakpoint('ms');
  const [store] = useLocalStorage<LessonProgress>(LESSON_PROGRESS_KEY);
  const getLessonUrl = useCallback(
    (course: string, lesson: string) => {
      if (!router.isReady) {
        return '#';
      }

      return `${router.asPath.split(/[?#]/)[0]}/${course}/${lesson}`.replace(/\/{2,}/g, '/');
    },
    [router]
  );

  const recentLessons = useMemo(
    () =>
      courses
        .map(course => {
          const normalizedLessons = course.lessons.map(lesson => ({
            ...lesson,
            course: course.title,
            progress: store?.[course.id]?.[lesson.id]?.[0],
            timestamp: store?.[course.id]?.[lesson.id]?.[1],
            url: getLessonUrl(course.slug, lesson.slug)
          }));

          return {
            ...course,
            lessons: normalizedLessons
          };
        })
        .flatMap(course => course.lessons)
        .filter(lesson => lesson.progress !== undefined && lesson.progress < 1 && lesson.timestamp)
        .sort((first, second) => (second.timestamp || 0) - (first.timestamp || 0))
        .slice(0, 3),

    [courses, getLessonUrl, store]
  );

  if (!courses.length || !recentLessons.length) {
    return null;
  }

  return (
    <Section {...rest} theme={'dark'}>
      <LessonsWrapper style={{ '--cards-height': recentLessons.length === 1 ? '352px' : '520px' } as CSSProperties}>
        {recentLessons.map(lesson => (
          <LessonCardWrapper data-lessons-number={recentLessons.length} key={lesson.id}>
            <RouterLink href={lesson.url}>
              <LessonCard
                as={lesson.course}
                asset={lesson.asset}
                data-grid-card
                description={lesson.title}
                duration={lesson.duration}
                index={lesson.index}
                key={lesson.id}
                layout={recentLessons.length === 1 && isDesktop ? 'horizontal' : 'vertical'}
                progress={lesson.progress}
                slug={lesson.slug}
                subtitle={lesson.description}
                thumbnail={lesson.thumbnail}
                title={lesson.title}
              />
            </RouterLink>
          </LessonCardWrapper>
        ))}
      </LessonsWrapper>
    </Section>
  );
};
