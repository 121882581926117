/**
 * Module dependencies.
 */

import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { FormFieldFile } from 'src/api/entities/form/types';
import { FormGroup, FormGroupProps } from 'src/components/core/forms/form-group';
import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import { formControlStyles } from 'src/components/core/forms/styles';
import { forwardRef } from 'react';
import { media } from 'src/styles/media';
import closeIcon from 'src/assets/svgs/16/close.svg';
import fileAttachment from 'src/assets/svgs/32/file-attachment.svg';
import styled from 'styled-components';

/**
 * `FileInputProps` type.
 */

export type FileInputProps = FormFieldFile['file'] &
  Omit<FormGroupProps, 'accept' | 'onDrop' | 'size'> &
  Pick<DropzoneOptions, 'accept' | 'onDrop'> & {
    onRemove?: (index: number) => void;
    value: File[] | undefined;
  };

/**
 * `fileItemAnimation` config.
 */

const fileItemAnimation: Omit<HTMLMotionProps<'div'>, 'ref'> = {
  animate: {
    height: 'auto',
    opacity: 1
  },
  exit: {
    height: 0,
    opacity: 0
  },
  initial: {
    height: 0,
    opacity: 0
  }
};

/**
 * `Content` styled component.
 */

const Content = styled.div`
  --background-active-color: var(--color-neutral10);
  --background-hover-color: var(--color-neutral05);
  --background-color: var(--color-neutral0);
  --border-hover-color: var(--color-neutral30);
  --border-color: var(--color-neutral10);
  --border-disabled-color: var(--color-neutral05);
  --icon-color: var(--color-neutral50);
  --text-color: var(--color-neutral50);
  --text-disabled-color: var(--color-neutral30);

  [data-theme='dark'] & {
    --background-active-color: var(--color-neutral80);
    --background-hover-color: var(--color-neutral90);
    --background-color: var(--color-neutral95);
    --border-hover-color: var(--color-neutral50);
    --border-color: var(--color-neutral70);
    --border-disabled-color: var(--color-neutral80);
    --icon-color: var(--color-neutral40);
    --text-color: var(--color-neutral40);
    --text-disabled-color: var(--color-neutral60);
  }

  &:hover {
    background-color: var(--background-hover-color);
    border-color: var(--border-hover-color);
  }

  &[data-active='true'] {
    background-color: var(--background-active-color);
    border-color: var(--border-hover-color);
  }

  :has(:disabled) {
    --icon-color: var(--color-neutral30);
    border-color: var(--border-disabled-color);
    color: var(--text-disabled-color);
  }

  background-color: var(--background-color);
  border: 1px dashed var(--border-color);
  border-radius: var(--border-radius-sm);
  color: var(--text-color);
  cursor: pointer;
  display: flex;
  gap: 16px;
  max-width: 100%;
  outline: none;
  overflow: hidden;
  padding: 32px 24px;
  text-overflow: ellipsis;
  transition: var(--transition-default);
  transition-property: background-color, border-color, color;
  white-space: nowrap;
  width: 100%;
  word-break: break-all;

  ${media.max.ms`
    padding: 24px;
  `}
`;

/**
 * Export `HelpText` styled component.
 */

export const HelpText = styled(Text).attrs({ variant: 'paragraph2' })`
  align-self: center;
`;

/**
 * `FileAttachmentIcon` styled component.
 */

const FileAttachmentIcon = styled(Svg).attrs({ icon: fileAttachment })`
  color: var(--icon-color);
  height: auto;
  width: 32px;
`;

/**
 * `FilesWrapper` styled component.
 */

const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
`;

/**
 * `FileItem` styled component.
 */

const FileItem = styled(FormGroup)`
  > div {
    ${formControlStyles}

    align-items: center;
    color: var(--color-neutral90);
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    padding-right: 40px;
    position: relative;

    [data-theme='dark'] & {
      color: var(--color-neutral0);
    }
  }
`;

/**
 * `RemoveButton` styled component.
 */

const RemoveButton = styled.span`
  color: var(--color-neutral30);
  cursor: pointer;
  height: 32px;
  padding: 8px;
  position: absolute;
  right: 8px;
  transition: color var(--transition-default);

  &:hover {
    color: var(--color-neutral20);
  }

  [data-theme='dark'] & {
    color: var(--color-neutral60);

    &:hover {
      color: var(--color-neutral50);
    }
  }
`;

/**
 * Export `FileDrop` component.
 */

export const FileDrop = forwardRef<HTMLInputElement, FileInputProps>((props, ref) => {
  const {
    accept = {},
    disabled,
    error,
    id,
    maxFiles,
    maxSize,
    minSize,
    onBlur,
    onDrop,
    onRemove,
    placeholder,
    value,
    ...rest
  } = props;

  const { getInputProps, getRootProps, isDragAccept } = useDropzone({
    accept,
    autoFocus: !!error,
    disabled,
    maxSize,
    minSize,
    multiple: maxFiles !== 1,
    onDrop
  });

  return (
    <>
      <FormGroup {...rest} disabled={disabled} error={error} id={id} value={value}>
        <Content {...getRootProps({ onBlur })} data-active={isDragAccept}>
          <FileAttachmentIcon />

          <HelpText>{placeholder}</HelpText>

          <input disabled={disabled} id={id} ref={ref} {...getInputProps()} />
        </Content>
      </FormGroup>

      <FilesWrapper>
        <AnimatePresence>
          {value?.map((file, index) => (
            <motion.div key={file.name} {...fileItemAnimation}>
              <FileItem id={`${id}-${file.name}`}>
                {file.name}

                {onRemove && (
                  <RemoveButton onClick={() => onRemove(index)}>
                    <Svg icon={closeIcon} size={'16px'} />
                  </RemoveButton>
                )}
              </FileItem>
            </motion.div>
          ))}
        </AnimatePresence>
      </FilesWrapper>
    </>
  );
});

/**
 * `FileDrop` display name.
 */

FileDrop.displayName = 'FileDrop';
