/**
 * Module dependencies.
 */

import { Checkbox as BaseCheckbox } from 'src/components/core/forms/checkbox';
import { CSSProperties } from 'react';
import { FormFieldCheckbox } from 'src/api/entities/form/types';
import { useFormContext } from 'react-hook-form';
import { useIsVisible } from './use-is-visible';
import { useTranslate } from 'src/context/i18n';

/**
 * Export `Checkbox` field component.
 */

export const Checkbox = (field: FormFieldCheckbox) => {
  const { checkbox, displayTemplate, id } = field;
  const isVisible = useIsVisible(field);
  const {
    formState: { errors },
    register
  } = useFormContext();

  const { t } = useTranslate();

  if (!isVisible) {
    return null;
  }

  return (
    <div style={{ '--display-template': displayTemplate } as CSSProperties}>
      <BaseCheckbox
        error={errors[checkbox.name]?.message as string}
        helpText={checkbox.description}
        id={id}
        label={checkbox.label}
        required={checkbox.isRequired}
        {...register(checkbox.name, {
          required: checkbox.isRequired && t('forms.fields.validation.required')
        })}
      />
    </div>
  );
};
