/**
 * Module dependencies.
 */

import { BaseButtonProps, BaseWrapper, ContentWrapper } from './common';
import { Spinner } from 'src/components/core/spinner';
import { Svg } from 'src/components/core/svg';
import { buttonThemes } from './icon-button-theme-config';
import { forwardRef } from 'react';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import styled, { css } from 'styled-components';

/**
 * Export `ButtonProps` type.
 */

export type ButtonProps = Omit<BaseButtonProps, 'children' | 'icon' | 'stretch'> & {
  icon: string | TrustedHTML;
  size?: 'large' | 'small';
  variant?: 'primary' | 'neutral' | 'ghost';
};

/**
 * `buttonSizes` constant.
 */

const buttonSizes = css`
  &[data-size='large'] {
    --button-icon-size: 24px;
    --button-padding: 13px;
  }

  &[data-size='small'] {
    --button-icon-size: 20px;
    --button-padding: 8px;
  }
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(BaseWrapper)`
  ${buttonSizes}
  ${buttonThemes}

  --button-padding-h: var(--button-padding);
  --button-padding-v: var(--button-padding);

  backdrop-filter: blur(var(--button-backdrop-blur));
  gap: 0;
`;

/**
 * Export `IconButton` component.
 */

export const IconButton = forwardRef<HTMLElement, ButtonProps>((props, ref) => {
  const { icon, isLoading, size = 'medium', variant = 'neutral', ...rest } = props;
  const isDesktop = useBreakpoint('md');
  const loadingSize = isDesktop ? 24 : 20;

  return (
    <Wrapper data-size={size} data-variant={variant} ref={ref} {...rest}>
      <ContentWrapper data-loading={isLoading} spin>
        {isLoading && (
          <Spinner
            active
            backgroundColor={'var(--button-background-color)'}
            color={'var(--button-text-color)'}
            size={loadingSize}
          />
        )}
      </ContentWrapper>

      <ContentWrapper data-loading={isLoading}>
        <Svg icon={icon} size={'var(--button-icon-size)'} />
      </ContentWrapper>
    </Wrapper>
  );
});

/**
 * `IconButton` display name.
 */

IconButton.displayName = 'IconButton';
