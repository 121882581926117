/**
 * Module dependencies.
 */

import { BaseSection } from 'src/api/entities/sections/base/types';
import { ComponentPropsWithoutRef, PropsWithChildren, forwardRef, useMemo } from 'react';
import { Container } from 'src/components/core/layout/container';
import { Description, Pretitle, Title } from './headings';
import { FooterCtaFields } from 'src/api/entities/footer-ctas/types';
import { RawHtml } from 'src/components/core/raw-html';
import { SectionFooterCTAs } from './section-footer-ctas';
import { linkBaseStyles } from 'src/components/core/links';
import { media } from 'src/styles/media';
import { scrollAnimations } from 'src/styles/animations';
import { useViewportObserver } from 'src/hooks/use-viewport-observer';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * Export `SectionProps` type.
 */

export type SectionProps = Omit<BaseSection, 'footerCtas'> &
  Omit<ComponentPropsWithoutRef<'section'>, 'title'> & {
    footerCtas?: NonNullable<FooterCtaFields>;
  };

/**
 * Export `SectionHeadingProps` type.
 */

export type SectionHeadingProps = Pick<SectionProps, 'description' | 'pretitle' | 'title'> &
  ComponentPropsWithoutRef<'div'>;

/**
 * Export `SectionWrapper` styled component.
 */

export const SectionWrapper = styled.section`
  &:first-child {
    --first-section-padding-top: var(--navbar-height) + var(--top-banner-height) + var(--nav-bottom-banner-height);
  }

  background-color: var(--color-background);
  color: var(--color-text);
  display: grid;
  grid-gap: 40px;
  padding: calc(48px + var(--first-section-padding-top, 0px)) 0 48px;
  position: relative;

  ${media.min.ms`
    grid-gap: 80px;
    padding: calc(72px + var(--first-section-padding-top, 0px)) 0 120px;
  `}
`;

/**
 * `HeadingWrapper` styled component.
 */

const HeadingWrapper = styled.div`
  display: grid;
  grid-gap: 16px;
  max-width: var(--container-narrow-max-width);
  padding: 56px var(--gutter) 0;

  a {
    ${linkBaseStyles}

    text-decoration: underline;
  }

  ${media.max.ms`
    > * {
      width: 100%;
    }
  `}

  ${media.min.ms`
    margin: 0 auto;
    padding-top: 120px;
    text-align: center;
  `}
`;

/**
 * Export `SectionHeading` component.
 */

export function SectionHeading(props: SectionHeadingProps) {
  const { description, pretitle, title, ...rest } = props;
  const [ref, intersectionPosition] = useViewportObserver<HTMLDivElement>({ rootMargin: '200px 0px 0px 0px' });
  const has = useMemo(
    () => ({
      description: !isEmpty(description),
      pretitle: !isEmpty(pretitle),
      title: !isEmpty(title)
    }),
    [description, pretitle, title]
  );

  if (isEmpty(description) && isEmpty(title) && isEmpty(pretitle)) {
    return null;
  }

  return (
    <HeadingWrapper ref={ref} {...rest}>
      {has.title && (
        <RawHtml
          {...(!has.pretitle && { elementProps: { as: 'h3' } })}
          data-scroll={scrollAnimations.blurFadeInVertical}
          data-scroll-delay={pretitle ? 100 : undefined}
          data-scroll-position={intersectionPosition}
          element={Title}
          style={{ order: 1 }}
        >
          {title}
        </RawHtml>
      )}

      {has.pretitle && (
        <RawHtml
          data-scroll={scrollAnimations.blurFadeInVertical}
          data-scroll-position={intersectionPosition}
          element={Pretitle}
          style={{ order: 0 }}
        >
          {pretitle}
        </RawHtml>
      )}

      {has.description && (
        <RawHtml
          data-scroll={scrollAnimations.blurFadeInVertical}
          data-scroll-delay={(has.title && has.pretitle && 200) || ((has.title || has.pretitle) && 100) || undefined}
          data-scroll-position={intersectionPosition}
          element={Description}
          style={{ order: 2 }}
        >
          {description}
        </RawHtml>
      )}
    </HeadingWrapper>
  );
}

/**
 * Export `Section` component.
 */
export const Section = forwardRef<HTMLElement, PropsWithChildren<SectionProps>>((props, ref) => {
  const { anchorId, children, containerSize, description, footerCtas, pretitle, theme, title, ...rest } = props;

  return (
    <SectionWrapper data-theme={theme ?? 'light'} ref={ref} {...omit(rest, 'id')} {...(anchorId && { id: anchorId })}>
      {(description || pretitle || title) && (
        <SectionHeading description={description} pretitle={pretitle} title={title} />
      )}

      <Container size={containerSize}>
        {children}

        {footerCtas && <SectionFooterCTAs {...footerCtas} />}
      </Container>
    </SectionWrapper>
  );
});

/**
 * Set display name.
 */

Section.displayName = 'Section';
