/**
 * Module dependencies.
 */

import { getStakingAssets } from './queries';
import { useQuery } from '@tanstack/react-query';

/**
 * Export `getStakingAssetsQueryKey`.
 */

export const getStakingAssetsQueryKey = () => ['staking-assets'];

/**
 * Export `useStakingAssets` hook.
 */

export function useStakingAssets() {
  return useQuery({
    placeholderData: previous => previous,
    queryFn: () => getStakingAssets(),
    queryKey: getStakingAssetsQueryKey()
  });
}
