/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/forms/date-time/common';
import { DayPicker, DayProps, Matcher } from 'react-day-picker';
import { Dropdown } from 'src/components/core/dropdown';
import { Svg } from 'src/components/core/svg';
import { useDelayedValue } from 'src/hooks/use-delayed-value';
import { useEffect, useMemo, useState } from 'react';
import { useFormat } from 'src/hooks/format/use-format';
import chevronLeft from 'src/assets/svgs/20/chevron-left.svg';
import chevronRight from 'src/assets/svgs/20/chevron-right.svg';
import styled from 'styled-components';

/**
 * Export `Props` type.
 */

export type Props = {
  isMonthOnly?: boolean;
  isOpen?: boolean;
  max?: Date;
  min?: Date;
  onChange: (date?: Date) => void;
  onRequestClose: () => void;
  suffix?: string;
  value?: Date;
};

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  gap: 24px 16px;
  grid-template-columns: repeat(4, 1fr);
`;

/**
 * `YearWrapper` styled component.
 */

const YearWrapper = styled.div`
  align-items: center;
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 2fr 1fr;
  margin-bottom: 24px;
`;

/**
 * `ChevronButtons` styled component.
 */

const ChevronButtons = styled.div`
  display: flex;
  gap: 16px;
  grid-column: 3 / 4;
  justify-content: center;

  > button {
    height: 20px;
    padding: 0;
    width: 20px;
  }
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  cursor: default;
  padding: 16px 16px 24px;
`;

/**
 * `StyledDayPicker` styled component.
 */

const StyledDayPicker = styled(DayPicker)`
  table {
    table-layout: fixed;
    width: 100%;
  }

  th {
    color: var(--color-neutral50);
    font-weight: 400;
    padding: 6px 0;

    [data-theme='dark'] & {
      color: var(--color-neutral40);
    }
  }

  td {
    padding-top: 24px;
    text-align: center;
  }
`;

/**
 * Export `Picker` component.
 */

export const Picker = ({ isMonthOnly, isOpen, max, min, onChange, onRequestClose, value }: Props) => {
  const [tab, setTab] = useState<'days' | 'months' | 'years'>('years');
  const now = useMemo(() => new Date(), []);
  const [yearPage, setYearPage] = useState((value ?? now).getFullYear());
  const [monthPage, setMonthPage] = useState((value ?? now).getMonth());
  const { formatMonthLong, formatMonthShort, formatWeekdayShort } = useFormat();
  const delayedValue = useDelayedValue(value?.getTime());

  useEffect(() => {
    if (!isOpen) {
      const date = delayedValue ? new Date(delayedValue) : now;
      setYearPage(date.getFullYear());
      setMonthPage(date.getMonth());
    }
  }, [isOpen, now, delayedValue]);

  return (
    <Dropdown isOpen={!!isOpen} onRequestClose={onRequestClose} position={'full'} style={{ borderRadius: 8 }} top={8}>
      <Content>
        {!isMonthOnly && tab === 'days' && (
          <>
            <YearWrapper>
              <Button
                onClick={event => {
                  event.stopPropagation();
                  setTab('months');
                }}
              >
                <b>{formatMonthLong(new Date(yearPage, monthPage))}</b>
              </Button>

              <ChevronButtons>
                <Button
                  aria-label={'Previous month'}
                  disabled={min && new Date(yearPage, monthPage) < min}
                  onClick={event => {
                    event.stopPropagation();
                    setMonthPage(monthPage => (monthPage + 11) % 12);

                    if (monthPage === 0) {
                      setYearPage(yearPage => yearPage - 1);
                    }
                  }}
                >
                  <Svg icon={chevronLeft} size={'20px'} />
                </Button>

                <Button
                  aria-label={'Next month'}
                  disabled={max && new Date(yearPage, monthPage + 1) > max}
                  onClick={event => {
                    event.stopPropagation();
                    setMonthPage(monthPage => (monthPage + 1) % 12);

                    if (monthPage === 11) {
                      setYearPage(yearPage => yearPage + 1);
                    }
                  }}
                >
                  <Svg icon={chevronRight} size={'20px'} />
                </Button>
              </ChevronButtons>
            </YearWrapper>

            <StyledDayPicker
              components={{
                CaptionLabel: () => <></>,
                Day: ({ day, ...rest }: DayProps) => (
                  <td {...rest}>
                    <Button disabled={rest.modifiers?.disabled} onClick={() => onChange(day.date)}>
                      {day.date.getDate()}
                    </Button>
                  </td>
                ),
                Nav: () => <></>,
                Weekdays: props => {
                  const date = new Date(yearPage, monthPage, 1);
                  date.setDate(date.getDate() - date.getDay() - 1);
                  const weekdays = Array.from({ length: 7 }).map((_value, index) => {
                    date.setDate(date.getDate() + 1);

                    return <th key={index}>{formatWeekdayShort(date)}</th>;
                  });

                  return <tr {...props}>{weekdays}</tr>;
                }
              }}
              disabled={[min && { before: min }, max && { after: max }].filter(Boolean) as Matcher[]}
              mode={'single'}
              month={new Date(yearPage, monthPage)}
              onSelect={onChange}
              required={false}
              selected={value}
            />
          </>
        )}

        {tab === 'months' && (
          <>
            <YearWrapper>
              <Button
                onClick={event => {
                  event.stopPropagation();
                  setTab('years');
                }}
              >
                <b>{yearPage}</b>
              </Button>

              <ChevronButtons>
                <Button
                  aria-label={'Previous year'}
                  disabled={min && yearPage <= min.getFullYear()}
                  onClick={event => {
                    event.stopPropagation();
                    setYearPage(yearPage => yearPage - 1);
                  }}
                >
                  <Svg icon={chevronLeft} size={'20px'} />
                </Button>

                <Button
                  aria-label={'Next year'}
                  disabled={max && yearPage >= max.getFullYear()}
                  onClick={event => {
                    event.stopPropagation();
                    setYearPage(yearPage => yearPage + 1);
                  }}
                >
                  <Svg icon={chevronRight} size={'20px'} />
                </Button>
              </ChevronButtons>
            </YearWrapper>

            <Grid>
              {Array.from({ length: 12 }).map((_value, index) => {
                const date = new Date(yearPage, index, 1);
                const month = formatMonthShort(date);

                return (
                  <Button
                    aria-label={`Select ${month}`}
                    data-selected={yearPage === value?.getFullYear() && date.getMonth() === value?.getMonth()}
                    disabled={
                      (min && new Date(date.getFullYear(), date.getMonth()) < min) ||
                      (max && new Date(date.getFullYear(), date.getMonth()) > max)
                    }
                    key={index}
                    onClick={event => {
                      event.stopPropagation();

                      if (isMonthOnly) {
                        onChange(date);
                        onRequestClose();

                        return;
                      }

                      setMonthPage(index);
                      setTab('days');
                    }}
                  >
                    {month}
                  </Button>
                );
              })}
            </Grid>
          </>
        )}

        {tab === 'years' && (
          <Grid>
            {Array.from({ length: 16 }).map((_value, index) => {
              const year = (min ? min.getFullYear() : (max ?? now).getFullYear() - 15) + index;

              return (
                <Button
                  aria-label={`Select ${year}`}
                  data-selected={year === value?.getFullYear()}
                  disabled={(min && year < min.getFullYear()) || (max && year > max.getFullYear())}
                  key={index}
                  onClick={event => {
                    event.stopPropagation();
                    setYearPage(year);
                    setTab('months');
                  }}
                >
                  {year}
                </Button>
              );
            })}
          </Grid>
        )}
      </Content>
    </Dropdown>
  );
};
