/**
 * Module dependencies.
 */

import { Svg } from 'src/components/core/svg';
import { Text } from 'src/components/core/text';
import checkIcon from 'src/assets/svgs/16/check.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props<Value extends string> = {
  onChange: (option: { label: string; value: Value }) => void;
  options: {
    label: string;
    value: Value;
  }[];
  value: Value;
};

/**
 * `Option` styled component.
 */

const Option = styled.li`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 16px;
  transition: background-color var(--transition-default);

  :hover {
    background-color: var(--color-neutral05);
  }

  [data-theme='dark'] &:hover {
    background-color: var(--color-neutral90);
  }

  span:first-child {
    font-weight: 400;
  }

  span:last-child {
    color: var(--color-primary);
    opacity: 0;
    transition: opacity var(--transition-default);
  }

  &[data-selected='true'] {
    span:first-child {
      font-weight: 700;
    }

    span:last-child {
      opacity: 1;
    }
  }
`;

/**
 * Export `DropdownOptions` component.
 */

export const DropdownOptions = <Value extends string>({ onChange, options, value }: Props<Value>) =>
  options?.length && (
    <ul>
      {options.map(option => (
        <Option data-selected={option.value === value} key={option.value} onClick={() => onChange(option)}>
          <Text variant={'paragraph2'}>{option.label}</Text>

          <Svg icon={checkIcon} size={'20px'} />
        </Option>
      ))}
    </ul>
  );
