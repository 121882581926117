/**
 * Module dependencies.
 */

import { DatePicker as BaseDatePicker } from 'src/components/core/forms/date-time/date-picker';
import { CSSProperties } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormFieldInput } from 'src/api/entities/form/types';
import { useIsVisible } from './use-is-visible';
import { useTranslate } from 'src/context/i18n';

/**
 * Export `DatePicker` field component.
 */

export const DatePicker = (field: FormFieldInput) => {
  const { displayTemplate, id, input } = field;
  const isVisible = useIsVisible(field);
  const {
    formState: { errors }
  } = useFormContext();

  const { t } = useTranslate();

  if (!isVisible) {
    return null;
  }

  return (
    <div style={{ '--display-template': displayTemplate } as CSSProperties}>
      <Controller
        name={input.name}
        render={({ field: { onBlur, onChange, ref, value } }) => (
          <BaseDatePicker
            error={errors[input.name]?.message as string}
            helpText={input.description}
            id={id}
            label={input.label}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={input.placeholder}
            ref={ref}
            required={input.isRequired}
            value={value}
            {...('dateValidation' in input && input.dateValidation === 'past' && { max: new Date() })}
            {...('dateValidation' in input && input.dateValidation === 'future' && { min: new Date() })}
          />
        )}
        rules={{ required: input.isRequired && t('forms.fields.validation.required') }}
      />
    </div>
  );
};
