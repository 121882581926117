/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/buttons/button';
import { PropsWithChildren } from 'react';
import { media } from 'src/styles/media';
import { transparentize } from 'src/styles/utils/colors';
import iconArrow from 'src/assets/svgs/24/arrow-down.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = PropsWithChildren<{
  hasMore?: boolean;
  label: string;
  onLoadMore: () => void;
}>;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

/**
 * `ChildrenWrapper` styled component.
 */

const ChildrenWrapper = styled.div`
  position: relative;
  width: 100%;

  &::after {
    background: linear-gradient(
      ${transparentize('white', 0)} 0%,
      ${transparentize('white', 0.3)} 50%,
      ${transparentize('white', 0.6)} 60%,
      var(--color-white) 90%
    );
    content: '';
    height: 160px;
    inset: auto -32px -16px;
    pointer-events: none;
    position: absolute;
    z-index: 1;

    [data-theme='dark'] & {
      background: linear-gradient(
        ${transparentize('neutral105', 0)} 0%,
        ${transparentize('neutral105', 0.3)} 50%,
        ${transparentize('neutral105', 0.6)} 60%,
        var(--color-neutral105) 90%
      );
    }

    ${media.min.sm`
      height: 184px;
    `}
  }
`;

/**
 * Export `LoadMore` component.
 */

export const LoadMore = ({ children, hasMore, label, onLoadMore }: Props) => {
  if (!hasMore) {
    return children;
  }

  return (
    <Wrapper>
      <ChildrenWrapper>{children}</ChildrenWrapper>

      <Button icon={iconArrow} onClick={onLoadMore} size={'large'} style={{ zIndex: 1 }} variant={'ghost'}>
        {label}
      </Button>
    </Wrapper>
  );
};
