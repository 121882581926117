/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, useMemo } from 'react';
import { Container } from 'src/components/core/layout/container';
import {
  Description,
  ListSectionWrapper,
  StyledSectionHeading,
  Subtitle,
  Title
} from 'src/components/sections/lists/shared/styles';

import { List } from 'src/components/sections/lists/shared/list';
import { ListSectionFragment } from 'src/api/entities/sections/list-section/types';
import { Marquee } from 'src/components/sections/lists/shared/marquee';
import { RawHtml } from 'src/components/core/raw-html';
import { SectionFooterCTAs } from 'src/components/core/layout/section-footer-ctas';
import { Text } from 'src/components/core/text';
import { fadeInLeftAnimation } from 'src/core/constants/motion';
import { headingStyles } from 'src/components/core/layout/headings';
import { linkAnimatedStyles } from 'src/components/core/links';
import { media } from 'src/styles/media';
import { motion } from 'framer-motion';
import { scrollAnimations } from 'src/styles/animations';
import { stripTags } from 'src/core/utils/html-client';
import { useViewportObserver } from 'src/hooks/use-viewport-observer';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * Export `ListSectionProps` type.
 */

export type ListSectionProps = Omit<ComponentPropsWithoutRef<'section'>, 'title'> & ListSectionFragment;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  &[data-show-marquee-mobile='false'] {
    padding-top: 48px;
  }

  &[data-show-marquee-desktop='false'] {
    ${media.min.ms`
      padding-top: 120px;
    `}
  }
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  gap: 40px;
  padding: 0 0 8px;
  position: relative;

  ${media.min.ms`
    gap: 80px;
    grid-template-columns: repeat(2, 1fr);
    padding: 0 16px 48px;
  `}
`;

/**
 * `MarqueWrapper` styled component.
 */

const MarqueWrapper = styled.div`
  display: none;

  &[data-show-marquee-mobile='true'] {
    display: block;
  }

  ${media.min.ms`
    &[data-show-marquee-desktop='true'] {
      display: block;
    }

    &[data-show-marquee-desktop='false'] {
      display: none;
    }
  `}
`;

/**
 * `RightColumn` styled component.
 */

const RightColumn = styled.div`
  ${media.min.ms`
    &[data-staggered-columns='true'] {
      margin-top: 94px;
    }
  `}
`;

/**
 * `StyledTitle` styled component.
 */

const StyledTitle = styled(Title)`
  padding: 16px 0;
`;

/**
 * `StyledSubtitle` styled component.
 */

const StyledSubtitle = styled(Subtitle)`
  padding: 16px 0;
`;

/**
 * `Lead` styled component.
 */

const Lead = styled(Text).attrs({ as: 'h5', fontWeight: 700, variant: 'subtitle2' })`
  ${headingStyles}

  margin-bottom: 32px;

  ${media.min.ms`
    padding-top: 16px;
  `}
`;

/**
 * `StyledDescription` styled component.
 */

const StyledDescription = styled(Description)`
  ${headingStyles}

  margin-top: 16px;

  a {
    color: var(--color-primary);

    ${linkAnimatedStyles}
  }
`;

/**
 * Export `ListSection` component.
 */

export function ListSection(props: ListSectionProps) {
  const {
    anchorId,
    containerSize,
    description,
    footerCtas,
    items,
    lead,
    marqueeShowDesktop,
    marqueeShowMobile,
    sectionHeadings,
    staggeredColumns,
    subtitle,
    theme,
    title,
    ...rest
  } = props;

  const [headingRef, headingPosition] = useViewportObserver<HTMLDivElement>({ threshold: 0.25 });
  const has = useMemo(
    () => ({
      description: !isEmpty(description),
      subtitle: !isEmpty(subtitle),
      title: !isEmpty(title)
    }),
    [description, subtitle, title]
  );

  const headingDelay = Object.values(has).filter(Boolean).length * 100;

  return (
    <ListSectionWrapper data-theme={theme ?? 'light'} {...omit(rest, 'id')} {...(anchorId && { id: anchorId })}>
      {!isEmpty(sectionHeadings) && (
        <StyledSectionHeading {...sectionHeadings} data-has-marquee={!!(title || subtitle)} />
      )}

      <MarqueWrapper data-show-marquee-desktop={!!marqueeShowDesktop} data-show-marquee-mobile={!!marqueeShowMobile}>
        <Marquee text={stripTags(title || subtitle)} />
      </MarqueWrapper>

      <StyledContainer
        data-show-marquee-desktop={!!marqueeShowDesktop}
        data-show-marquee-mobile={!!marqueeShowMobile}
        size={containerSize}
      >
        <Grid>
          <div ref={headingRef}>
            {has.title && (
              <RawHtml
                data-scroll={scrollAnimations.blurFadeInVertical}
                data-scroll-position={headingPosition}
                element={StyledTitle}
              >
                {title}
              </RawHtml>
            )}

            {has.subtitle && (
              <RawHtml
                data-scroll={scrollAnimations.blurFadeInVertical}
                data-scroll-delay={has.title ? 100 : undefined}
                data-scroll-position={headingPosition}
                element={StyledSubtitle}
              >
                {subtitle}
              </RawHtml>
            )}

            {has.description && (
              <RawHtml
                data-scroll={scrollAnimations.blurFadeInVertical}
                data-scroll-delay={headingDelay - 100}
                data-scroll-position={headingPosition}
                element={StyledDescription}
              >
                {description}
              </RawHtml>
            )}
          </div>

          {(!isEmpty(lead) || !!items?.length) && (
            <RightColumn data-staggered-columns={staggeredColumns}>
              {!isEmpty(lead) && (
                <motion.div {...fadeInLeftAnimation(headingDelay / 1000)}>
                  <RawHtml element={Lead}>{lead}</RawHtml>
                </motion.div>
              )}

              {!!items?.length && <List delay={(headingDelay + 80) / 1000} items={items} />}
            </RightColumn>
          )}
        </Grid>

        {footerCtas && <SectionFooterCTAs {...footerCtas} />}
      </StyledContainer>
    </ListSectionWrapper>
  );
}
