/**
 * Module dependencies.
 */

import { Section } from 'src/components/core/layout/section';
import { TeamMemberCard } from './team-member-card';
import { TeamMemberDetailsDrawer } from './team-member-details-drawer';
import { TeamMemberFragment, TeamMembersSectionFragment } from 'src/api/entities/sections/team-members/types';
import { media } from 'src/styles/media';
import { useState } from 'react';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * `TeamMembersWrapper` styled component.
 */

const TeamMembersWrapper = styled.div`
  display: grid;
  gap: var(--grid-row-gap);
  grid-template-columns: repeat(auto-fill, 306px);
  justify-content: center;
  margin: 0 var(--gutter-cards);

  ${media.max.ms`
    grid-template-columns: repeat(2, 1fr);
  `}
`;

/**
 * Export `TeamMembersSection` component.
 */

export const TeamMembersSection = ({ members, ...sectionProps }: TeamMembersSectionFragment) => {
  const [modalContent, setModalContent] = useState<TeamMemberFragment | null>(null);

  return (
    <Section {...omit(sectionProps, 'id')} containerSize={'default'}>
      <TeamMembersWrapper>
        {members.map(teamMember => (
          <TeamMemberCard
            aria-label={`View details of ${teamMember.name}`}
            key={teamMember.id}
            {...teamMember}
            onClick={() => setModalContent(teamMember)}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();
                setModalContent(teamMember);
              }
            }}
            role={'button'}
            tabIndex={0}
          />
        ))}
      </TeamMembersWrapper>

      <TeamMemberDetailsDrawer
        {...sectionProps}
        description={modalContent?.description}
        isOpen={modalContent !== null}
        jobTitle={modalContent?.jobTitle}
        name={modalContent?.name}
        onRequestClose={() => setModalContent(null)}
      />
    </Section>
  );
};
