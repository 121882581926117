/**
 * Module dependencies.
 */

import { Button } from 'src/components/core/forms/date-time/common';
import { Dropdown } from 'src/components/core/dropdown';
import { useIsSystem12H } from 'src/hooks/use-is-system-12h';
import { useMemo } from 'react';
import styled from 'styled-components';

/**
 * Export `Props` type.
 */

export type Props = {
  interval?: number;
  isOpen?: boolean;
  onChange: (time: string) => void;
  onRequestClose: () => void;
  value: string;
};

/**
 * `formatTimePart` util.
 */

const formatTimePart = (value: number) => String(value).padStart(2, '0');

/**
 * `Content` styled component.
 */

const Content = styled.div`
  cursor: default;
  display: grid;
  grid-auto-flow: column;

  > div {
    max-height: 256px;
    overflow-y: auto;
    padding: 16px 8px;
  }
`;

/**
 * Export `Picker` component.
 */

export const Picker = ({ interval = 5, isOpen, onChange, onRequestClose, value }: Props) => {
  const [valueHours, valueMinutes] = useMemo(() => (value.length ? value.split(':') : []), [value]);
  const valueHoursNumber = Number(valueHours);
  const isSystem12h = useIsSystem12H();

  return (
    <Dropdown
      focusable={false}
      isOpen={!!isOpen}
      onRequestClose={onRequestClose}
      style={{ borderRadius: 8, maxWidth: 196 }}
      top={8}
    >
      <Content>
        <div>
          {Array.from({ length: isSystem12h ? 12 : 24 }).map((_empty, index) => {
            const hours = formatTimePart(index);

            return (
              <Button
                data-selected={valueHours === hours || (isSystem12h && valueHoursNumber % 12 === index)}
                key={index}
                onClick={event => {
                  event.stopPropagation();
                  onChange(
                    `${isSystem12h && valueHoursNumber >= 12 ? formatTimePart(index + 12) : hours}:${valueMinutes ?? '00'}`
                  );
                }}
              >
                {index === 0 && isSystem12h ? '12' : hours}
              </Button>
            );
          })}
        </div>

        <div>
          {Array.from({ length: 60 / interval }).map((_empty, index) => {
            const minutes = formatTimePart(index * interval);

            return (
              <Button
                data-selected={valueMinutes === minutes}
                key={index}
                onClick={event => {
                  event.stopPropagation();
                  onChange(`${valueHours ?? '00'}:${minutes}`);
                }}
              >
                {minutes}
              </Button>
            );
          })}
        </div>

        {isSystem12h && (
          <div>
            <Button
              data-selected={valueHoursNumber < 12}
              onClick={event => {
                event.stopPropagation();
                onChange(`${formatTimePart(valueHoursNumber % 12)}:${valueMinutes ?? '00'}`);
              }}
            >
              {'AM'}
            </Button>

            <Button
              data-selected={valueHoursNumber >= 12}
              onClick={event => {
                event.stopPropagation();
                onChange(`${formatTimePart((valueHoursNumber % 12) + 12)}:${valueMinutes ?? '00'}`);
              }}
            >
              {'PM'}
            </Button>
          </div>
        )}
      </Content>
    </Dropdown>
  );
};
