/**
 * Module dependencies.
 */

import { FormFieldCopy } from 'src/api/entities/form/types';
import { RawHtml } from 'src/components/core/raw-html';
import { RichText } from 'src/components/rich-text';
import { Text } from 'src/components/core/text';
import { blurFadeInTopAnimation } from 'src/core/constants/motion';
import { headingStyles } from 'src/components/core/layout/headings';
import { motion } from 'framer-motion';
import { useIsVisible } from './use-is-visible';
import styled from 'styled-components';

/**
 * `CopyWrapper` styled component.
 */

const CopyWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px;
`;

/**
 * `CopyTitle` styled component.
 */

const CopyTitle = styled(Text).attrs({ variant: 'subtitle2' })`
  ${headingStyles}

  font-weight: 700;
`;

/**
 * Export `Copy` field component.
 */

export const Copy = (field: FormFieldCopy) => {
  const isVisible = useIsVisible(field);

  if (!isVisible) {
    return null;
  }

  return (
    <CopyWrapper key={field.id} {...blurFadeInTopAnimation()}>
      {field.copy.title && <RawHtml element={CopyTitle}>{field.copy.title}</RawHtml>}

      {field.copy.description && <RichText>{field.copy.description}</RichText>}
    </CopyWrapper>
  );
};
