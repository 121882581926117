/**
 * Module dependencies.
 */

import axios from 'redaxios';

/**
 * Constants.
 */

const upholdApiUrl = process.env.NEXT_PUBLIC_UPHOLD_API_URL;
const baseURL = process.env.NEXT_PUBLIC_ENABLE_UPHOLD_API_PROXY === 'true' ? '/api/uphold' : upholdApiUrl;

/**
 * Export `axiosUpholdInstance`.
 */

export const axiosUpholdInstance = axios.create({ baseURL });
