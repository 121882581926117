/**
 * Module dependencies.
 */

import {
  AsYouType,
  getCountries,
  getCountryCallingCode,
  getExampleNumber,
  parsePhoneNumberWithError
} from 'libphonenumber-js';

import { Input as BaseInput } from 'src/components/core/forms/input';
import { CSSProperties, ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CountryCode } from 'libphonenumber-js/types';
import { FormFieldInput } from 'src/api/entities/form/types';
import { FormGroup } from 'src/components/core/forms/form-group';
import { Option, Select } from 'src/components/core/forms/select';
import { formControlStyles } from 'src/components/core/forms/styles';
import { useIsVisible } from './use-is-visible';
import { useSettings } from 'src/context/settings';
import { useTranslate } from 'src/context/i18n';
import examples from 'libphonenumber-js/mobile/examples';
import styled from 'styled-components';

/**
 * `ContentRow` styled component.
 */

const ContentRow = styled.div`
  align-items: center;
  display: grid;
  gap: 8px;
  grid-area: input;
  grid-template-columns: 96px 1fr;
  padding: 0;
  position: relative;

  > * {
    height: 56px;
  }
`;

/**
 * `InputWithCallingCode` styled component.
 */

const InputWithCallingCode = styled.div`
  ${formControlStyles}

  align-items: center;
  display: flex;
  gap: 4px;
  padding: 0 0 0 16px;

  > * {
    height: 56px;
  }

  input {
    padding-left: 0;
  }
`;

/**
 * `CallingCode` styled component.
 */

const CallingCode = styled.div`
  align-items: center;
  display: flex;
  font-weight: 400;
  justify-content: center;
  padding-bottom: 2px;
`;

/**
 * `CountryOption` component.
 */

const CountryOption = ({ option }: { option: Option<{ callingCode: string }> }) => {
  return <div>{`${option.label} (${option.props?.callingCode})`}</div>;
};

/**
 * `CountryValue` component.
 */

const CountryValue = ({ option }: { option?: Option<{ callingCode: string }> | null }) => {
  return <div>{option?.value}</div>;
};

/**
 * Export `PhoneInput` field component.
 */

export const PhoneInput = (field: FormFieldInput) => {
  const { displayTemplate, id, input } = field;
  const isVisible = useIsVisible(field);
  const {
    formState: { errors },
    getValues,
    register,
    setFocus,
    setValue,
    watch
  } = useFormContext();

  const { language, t } = useTranslate();
  const { userCountry } = useSettings();

  const countryOptions = useMemo(
    () =>
      getCountries().map(country => {
        const countryNames = new Intl.DisplayNames([language], { type: 'region' });
        const countryName = countryNames.of(country);

        return {
          label: countryName ?? country,
          props: { callingCode: `+${getCountryCallingCode(country)}` },
          value: country
        };
      }),
    [language]
  );

  const selectedCountryIsoCode = watch(`${input.name}.country`);

  const placeholder = useMemo(() => {
    try {
      const example = getExampleNumber(selectedCountryIsoCode, examples);

      return example?.formatNational();
    } catch {
      return;
    }
  }, [selectedCountryIsoCode]);

  const handleNumberChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.target.value = event.target.value.replace(/\+/g, '');

      // Format only if cursor is at the end of input to avoid the cursor being moved unexpectedly.
      if (event.target.selectionStart === event.target.value.length) {
        const formatter = new AsYouType(selectedCountryIsoCode);
        event.target.value = formatter.input(event.target.value);
      }
    },
    [selectedCountryIsoCode]
  );

  const validateNumber = useCallback(
    (value: string) => {
      if (!value || !selectedCountryIsoCode) {
        return true;
      }

      try {
        const phoneNumber = parsePhoneNumberWithError(value, selectedCountryIsoCode);

        if (!phoneNumber.isValid() || phoneNumber.country !== selectedCountryIsoCode) {
          return t('forms.fields.validation.phoneNumber');
        }

        return true;
      } catch {
        return t('forms.fields.validation.phoneNumber');
      }
    },
    [selectedCountryIsoCode, t]
  );

  useEffect(() => {
    setValue(`${input.name}.country`, userCountry?.toUpperCase() ?? null);
  }, [input.name, setValue, userCountry]);

  if (!isVisible) {
    return null;
  }

  return (
    <div style={{ '--display-template': displayTemplate } as CSSProperties}>
      <FormGroup
        // @ts-expect-error: nested error messages.
        error={errors[input.name]?.number?.message ?? errors[input.name]?.country?.message}
        helpText={input.description}
        id={id}
        label={input.label}
        required={input.isRequired}
      >
        <ContentRow>
          <Controller
            name={`${input.name}.country`}
            render={({ field: { onBlur, onChange, ref, value } }) => (
              <Select
                components={{
                  Option: CountryOption,
                  Value: CountryValue
                }}
                dropdownPosition={'left'}
                filterPlaceholder={t('actions.search')}
                id={`${id}-country`}
                isFilterable
                onBlur={onBlur}
                onChange={({ value }) => {
                  const formatter = new AsYouType(value as CountryCode);

                  setValue(`${input.name}.number`, formatter.input(getValues(`${input.name}.number`)));
                  onChange(value);
                  setFocus(`${input.name}.number`);
                }}
                options={countryOptions}
                ref={ref}
                style={{ position: 'static' }}
                value={countryOptions.find(option => option.value === value) ?? null}
                virtualized
              />
            )}
            rules={{ required: input.isRequired && t('forms.fields.validation.required') }}
          />

          <InputWithCallingCode onClick={() => setFocus(`${input.name}.number`)}>
            {selectedCountryIsoCode && <CallingCode>{`+${getCountryCallingCode(selectedCountryIsoCode)}`}</CallingCode>}

            <BaseInput
              id={`${id}-number`}
              placeholder={placeholder ?? input.placeholder}
              type={'tel'}
              {...register(`${input.name}.number`, {
                onChange: handleNumberChange,
                required: input.isRequired && t('forms.fields.validation.required'),
                validate: validateNumber
              })}
              variant={'ghost'}
            />
          </InputWithCallingCode>
        </ContentRow>
      </FormGroup>
    </div>
  );
};
