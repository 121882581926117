/**
 * Module dependencies.
 */

import { ComponentPropsWithoutRef, forwardRef, useState } from 'react';
import { FormGroup, FormGroupProps } from 'src/components/core/forms/form-group';
import { Icon, dateTimeFormControlStyles } from 'src/components/core/forms/date-time/common';
import { Picker } from './picker';
import calendarIcon from 'src/assets/svgs/20/calendar.svg';
import closeIcon from 'src/assets/svgs/16/close.svg';
import omit from 'lodash/omit';
import styled from 'styled-components';

/**
 * Export `DatePickerProps` interface.
 */

export type DatePickerProps = Omit<
  Omit<ComponentPropsWithoutRef<'input'>, 'size'> & FormGroupProps,
  'max' | 'min' | 'onChange' | 'value'
> & {
  max?: Date;
  min?: Date;
  onChange: (date?: Date) => void;
  value?: Date;
};

/**
 * FormControl styled component.
 */

const FormControl = styled.div<Pick<DatePickerProps, 'error'>>`
  ${dateTimeFormControlStyles}

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

/**
 * Export `DatePicker` component.
 */

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>((props, ref) => {
  const {
    className,
    disabled,
    error,
    helpText,
    id,
    label,
    max,
    min,
    onChange,
    placeholder,
    size = 'default',
    value,
    variant = 'filled',
    ...rest
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <FormGroup
      aria-expanded={isOpen}
      className={className}
      data-size={size}
      data-variant={variant}
      disabled={disabled}
      error={error}
      helpText={helpText}
      id={id}
      label={label}
      onClick={event => {
        event.stopPropagation();
        setIsOpen(isOpen => !isOpen);
      }}
      role={'button'}
      style={{
        position: 'relative'
      }}
      tabIndex={0}
      value={value}
    >
      <div style={{ gridArea: 'input', position: 'relative' }}>
        <Icon icon={calendarIcon} size={'20px'} style={{ left: 14 }} />

        <FormControl {...omit(rest, ['as'])} error={error} id={id} ref={ref}>
          {value?.toLocaleDateString?.() ?? placeholder}
        </FormControl>

        {value && (
          <Icon
            icon={closeIcon}
            onClick={event => {
              event.stopPropagation();
              onChange();
            }}
            size={'16px'}
            style={{ right: 12 }}
          />
        )}

        <Picker
          isOpen={isOpen}
          max={max}
          min={min}
          onChange={onChange}
          onRequestClose={() => setIsOpen(false)}
          value={value}
        />
      </div>
    </FormGroup>
  );
});

/**
 * `DatePicker` display name.
 */

DatePicker.displayName = 'DatePicker';
