/**
 * Module dependencies.
 */

import { FormFieldCommon } from 'src/api/entities/form/types';
import { useFormContext } from 'react-hook-form';

/**
 * Export `useIsVisible` hook.
 */

export function useIsVisible(field: FormFieldCommon) {
  const { watch } = useFormContext();

  if (!field.isConditional) {
    return true;
  }

  const value = watch(field.master.name);

  return value === field.master.value;
}
