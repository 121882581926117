/**
 * Module dependencies.
 */

import { StakingAsset } from './types';
import { axiosNextInstance } from 'src/api/lib/next';

/**
 * Export `getStakingAssets` request.
 */

export const getStakingAssets = async (): Promise<StakingAsset[]> => {
  const response = await axiosNextInstance.get('staking-assets');

  return response.data;
};
