/**
 * Module dependencies.
 */

import { FormIntegrationData } from 'src/api/entities/form/types';
import { axiosNextInstance } from 'src/api/lib/next';
import { formIntegrationActions } from 'src/core/constants/forms';
import { sendGTMEvent, sendSnowplowEvent } from 'src/core/utils/analytics';
import { toast } from 'src/context/toast';
import { useMutation } from '@tanstack/react-query';
import { useSettings } from 'src/context/settings';
import { useTranslate } from 'src/context/i18n';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';

/**
 * `Props` type.
 */

type Props = {
  handleReset: () => void;
  hasSubmissionEvents?: boolean;
  integrationData: FormIntegrationData;
  name: string;
};

/**
 * Export `useFormMutation` hook.
 */

export function useFormMutation({ handleReset, hasSubmissionEvents, integrationData, name }: Props) {
  const { t } = useTranslate();
  const { queryCaptureParametersWhitelist } = useSettings();

  const mutation = useMutation({
    mutationFn: async (gnosticData: Record<string, any>) => {
      const data = mapKeys(gnosticData, (_value, key) => key.replace(new RegExp(`^${name}-`), ''));

      if (integrationData.integration === 'url_forward' && 'urlForward' in integrationData) {
        const urlForward = integrationData?.urlForward;
        const urlWithParams = new URL(urlForward.url);
        const allParams = {
          ...Object.fromEntries((urlForward.extraParams ?? [])?.map(({ name, value }) => [name, value])),
          ...omit(data, 'terms')
        } as Record<string, string>;

        if (queryCaptureParametersWhitelist?.length) {
          const searchParams = new URLSearchParams(window.location.search);

          for (const key of queryCaptureParametersWhitelist) {
            const value = searchParams.get(key);

            if (value) {
              urlWithParams.searchParams.append(key, value);
            }
          }
        }

        for (const [key, value] of Object.entries(allParams)) {
          if (value && typeof value === 'object' && 'number' in value) {
            urlWithParams.searchParams.append(key, (value as any).number);
            continue;
          }

          if (value) {
            urlWithParams.searchParams.append(key, value);
          }
        }

        window.open(urlWithParams.toString(), urlForward.openInNewTab ? '_blank' : '_self');

        return await Promise.resolve();
      }

      let action = null;
      let integrationFormData = null;
      let recaptchaToken = null;

      if (integrationData.integration === 'kickofflabs' && 'kickofflabs' in integrationData) {
        integrationFormData = integrationData?.kickofflabs;
        action = formIntegrationActions.kickofflabs;
      }

      if (integrationData.integration === 'directus' && 'directus' in integrationData) {
        integrationFormData = integrationData?.directus;
        action = formIntegrationActions.directus;
      }

      if (process.env.NEXT_PUBLIC_RECAPTCHA_ENABLED === 'true' && action) {
        recaptchaToken = await window.grecaptcha.enterprise?.execute(process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY, {
          action
        });
      }

      return axiosNextInstance.post('form-section-submit', {
        action,
        formData: omit(data, 'terms'),
        integrationFormData,
        recaptchaToken
      });
    },
    onError: () => {
      if (hasSubmissionEvents) {
        sendSnowplowEvent('form', 'submit', { label: `${name}_error` });
        sendGTMEvent('form', 'submit', `${name}_error`);
      }

      toast.error(t('forms.messages.submit.error'));
    },
    onSuccess: () => {
      if (hasSubmissionEvents) {
        sendSnowplowEvent('form', 'submit', { label: `${name}_success` });
        sendGTMEvent('form', 'submit', `${name}_success`);
      }

      toast.success(t('forms.messages.submit.success'));
      handleReset();
    }
  });

  return mutation;
}
