/**
 * Module dependencies.
 */

import { Svg } from 'src/components/core/svg';
import { formControlSizes, formControlStyles, formControlVariants } from 'src/components/core/forms/styles';
import { textStyles } from 'src/styles/typography';
import styled, { css } from 'styled-components';

/**
 * Export `dateTimeFormControlStyles` css.
 */

export const dateTimeFormControlStyles = css`
  ${formControlStyles}

  padding-left: 42px;
  padding-right: 40px;

  ${Object.entries(formControlVariants).map(
    ([key, value]) => `
      [data-variant='${key}'] & {
        ${value}
      }
    `
  )}

  ${Object.entries(formControlSizes).map(
    ([key, value]) => `
      [data-size='${key}'] & {
        ${value}
      }
    `
  )}
`;

/**
 * Export `Icon` styled component.
 */

export const Icon = styled(Svg)`
  color: var(--color-neutral30);
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: color var(--transition-default);

  [data-theme='dark'] & {
    color: var(--color-neutral60);
  }

  &:hover {
    color: var(--color-neutral20);
  }

  [data-theme='dark'] &:hover {
    color: var(--color-neutral50);
  }
`;

/**
 * Export `Button` styled component.
 */

export const Button = styled.button.attrs({ type: 'button' })`
  ${textStyles.paragraph3}

  --background-color-hover: var(--color-neutral10);
  --background-color-selected: var(--color-primary);
  --color-disabled: var(--color-neutral30);
  --color-selected: var(--color-white);
  --color: var(--color-neutral70);

  [data-theme='dark'] & {
    --background-color-hover: var(--color-neutral80);
    --color-disabled: var(--color-neutral60);
    --color-selected: var(--color-neutral105);
    --color: var(--color-neutral10);
  }

  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 48px;
  color: var(--color);
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  outline: none !important;
  padding: 6px 8px;
  transition: var(--transition-default);
  transition-property: background-color, color;
  white-space: nowrap;
  width: 100%;

  &:hover,
  &:focus {
    background-color: var(--background-color-hover);
  }

  &:disabled {
    color: var(--color-disabled);
    pointer-events: none;
  }

  [data-selected='true'] &,
  &[data-selected='true'] {
    background-color: var(--background-color-selected);
    color: var(--color-selected);
  }

  [data-outside='true'] &,
  &[data-outside='true'] {
    color: var(--color-disabled);
  }
`;
