/**
 * Module dependencies.
 */

import { TimePicker as BaseTimePicker } from 'src/components/core/forms/date-time/time-picker';
import { CSSProperties } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormFieldInput } from 'src/api/entities/form/types';
import { useIsVisible } from './use-is-visible';
import { useTranslate } from 'src/context/i18n';

/**
 * Export `TimePicker` field component.
 */

export const TimePicker = (field: FormFieldInput) => {
  const { displayTemplate, id, input } = field;
  const isVisible = useIsVisible(field);
  const {
    formState: { errors }
  } = useFormContext();

  const { t } = useTranslate();

  if (!isVisible) {
    return null;
  }

  return (
    <div style={{ '--display-template': displayTemplate } as CSSProperties}>
      <Controller
        name={input.name}
        render={({ field: { onBlur, onChange, ref, value } }) => (
          <BaseTimePicker
            error={errors[input.name]?.message as string}
            helpText={input.description}
            id={id}
            label={input.label}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            required={input.isRequired}
            value={value}
          />
        )}
        rules={{ required: input.isRequired && t('forms.fields.validation.required') }}
      />
    </div>
  );
};
