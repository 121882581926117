/**
 * Module dependencies.
 */

import { CSSProperties } from 'react';
import { FormFieldRadiogroup } from 'src/api/entities/form/types';
import { OptionsGroup } from 'src/components/core/forms/options-group';
import { useFormContext } from 'react-hook-form';
import { useIsVisible } from './use-is-visible';

/**
 * Export `Radiogroup` field component.
 */

export const Radiogroup = (field: FormFieldRadiogroup) => {
  const { displayTemplate, id, radiogroup } = field;
  const isVisible = useIsVisible(field);
  const {
    formState: { errors }
  } = useFormContext();

  if (!isVisible) {
    return null;
  }

  return (
    <div style={{ '--display-template': displayTemplate } as CSSProperties}>
      <OptionsGroup
        error={errors[radiogroup.name]?.message as string}
        id={id}
        label={radiogroup.label}
        name={radiogroup.name}
        options={radiogroup.options}
        required={radiogroup.isRequired}
        sublabel={radiogroup.sublabel}
        type={'radio'}
      />
    </div>
  );
};
