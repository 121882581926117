/**
 * Module dependencies.
 */

import { DescriptionSectionFragment } from 'src/api/entities/sections/description/types';
import { Section } from 'src/components/core/layout/section';
import { Text } from 'src/components/core/text';
import { media } from 'src/styles/media';
import { useIsClient } from 'src/context/client';
import { useMemo, useRef, useState } from 'react';
import { useMotionValueEvent, useScroll } from 'framer-motion';
import isNil from 'lodash/isNil';
import styled from 'styled-components';

/**
 * `Description` styled component.
 */

const Description = styled(Text).attrs({ as: 'div', fontWeight: 400, variant: 'heading3' })`
  color: var(--color-neutral70);
  text-align: left;

  &[data-narrow='true'] {
    ${media.min.ms`
      max-width: 760px;
    `}
  }
`;

/**
 * `Word` styled component.
 */

const Word = styled.span`
  --description-section-description-color: var(--color-neutral70);

  color: var(--description-section-description-color);
  transition: color 0.2s linear;

  &[data-active='true'] {
    --description-section-description-color: var(--color-neutral0);
  }

  &[data-theme='light'] {
    --description-section-description-color: var(--color-neutral05);

    &[data-active='true'] {
      --description-section-description-color: var(--color-neutral70);
    }
  }
`;

/**
 * `getWordIndex` function.
 */

function getWordIndex(words: string[], word: string, index: number) {
  const validWordIndex = words.slice(0, index).filter(part => part !== '\n' && part.trim().length > 0).length;

  return word.trim().length > 0 && word !== '\n' ? validWordIndex : -1;
}

/**
 * `DescriptionSectionClient` component.
 */

function DescriptionSectionClient(props: DescriptionSectionFragment) {
  const { description, isNarrowText, theme = 'light', ...rest } = props;
  const sectionRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress } = useScroll();
  const [activeWordIndex, setActiveWordIndex] = useState(0);
  const { totalWords, wordsAndBreaks } = useMemo(() => {
    const wordsAndBreaks = description?.split(/(\s|\n+)/).filter(part => part.length > 0) ?? [];
    const totalWords = wordsAndBreaks?.filter(part => part !== '\n' && part.trim().length > 0).length;

    return { totalWords, wordsAndBreaks };
  }, [description]);

  useMotionValueEvent(scrollYProgress, 'change', (latest: number) => {
    const { height, top } = sectionRef.current?.getBoundingClientRect?.() || {};

    if (isNil(top) || isNil(height)) {
      return;
    }

    const heightOffset = height * 1.2;
    const offset = window.innerHeight / 1.2;
    const min = top - offset;
    const max = min + heightOffset;

    if (latest < min) {
      if (activeWordIndex !== 0) {
        setActiveWordIndex(0);
      }

      return;
    }

    if (latest > max) {
      if (activeWordIndex !== totalWords) {
        setActiveWordIndex(totalWords);
      }

      return;
    }

    const scrollPosition = latest - min;
    const activeIndex = Math.floor((scrollPosition / heightOffset) * totalWords);

    setActiveWordIndex(activeIndex);
  });

  return (
    <Section ref={sectionRef} theme={theme} {...rest}>
      <Description data-narrow={isNarrowText}>
        {wordsAndBreaks.map((word, index) => {
          const wordIndex = getWordIndex(wordsAndBreaks, word, index);

          if (word === '\n') {
            return <br key={index} />;
          }

          return (
            <Word data-active={activeWordIndex >= wordIndex} data-theme={theme} key={index}>
              {`${word} `}
            </Word>
          );
        })}
      </Description>
    </Section>
  );
}

/**
 * Export `DescriptionSection` component.
 */

export const DescriptionSection = (props: DescriptionSectionFragment) => {
  const isClient = useIsClient();

  return isClient ? <DescriptionSectionClient {...props} /> : <div style={{ height: '50vh' }} />;
};
