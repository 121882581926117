/**
 * Module dependencies.
 */

import { CSSProperties } from 'react';
import { FormFieldCheckboxgroup } from 'src/api/entities/form/types';
import { OptionsGroup } from 'src/components/core/forms/options-group';
import { useFormContext } from 'react-hook-form';
import { useIsVisible } from './use-is-visible';

/**
 * Export `Checkboxgroup` field component.
 */

export const Checkboxgroup = (field: FormFieldCheckboxgroup) => {
  const { checkboxgroup, displayTemplate, id } = field;
  const isVisible = useIsVisible(field);
  const {
    formState: { errors }
  } = useFormContext();

  if (!isVisible) {
    return null;
  }

  return (
    <div style={{ '--display-template': displayTemplate } as CSSProperties}>
      <OptionsGroup
        error={errors[checkboxgroup.name]?.message as string}
        id={id}
        label={checkboxgroup.label}
        name={checkboxgroup.name}
        options={checkboxgroup.options}
        required={checkboxgroup.isRequired}
        sublabel={checkboxgroup.sublabel}
        type={'checkbox'}
      />
    </div>
  );
};
